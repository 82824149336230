import { useState } from "react";
import { 
  Card, 
  CardContent, 
  Button, 
  Typography,
  Box,
  CircularProgress
} from "@material-ui/core";
import moment from "moment";
import { DatePicker, Form, Input, Select, message } from "antd";
import { ReportAPI } from "../../../apis/ReportAPI";

const { Option } = Select;

const ReportRequestForm = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const requestData = {
        type: values.reportType,
        startDate: values.startDate.format("YYYY-MM-DD"),
        endDate: values.endDate.format("YYYY-MM-DD"),
        priority: values.priority,
        phone: values.phone.replace(/\D/g, ''), // Remove non-numeric characters
      };

      const response = await ReportAPI.mutateReportRequest(requestData);

      if (response?.data?.success) {
        message.success('Report request submitted successfully!');
        form.resetFields();
      } else {
        throw new Error(response?.data?.message || 'Failed to submit request');
      }
    } catch (error) {
      console.error('Error submitting request:', error);
      message.error(error.message || 'An error occurred while submitting the request');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography align="center" variant="h4" gutterBottom>
          Create Request
        </Typography>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          style={{ maxWidth: "100%" }}
        > 
          <Form.Item
            name="reportType"
            label="Report Type"
            rules={[{ required: true, message: "Please select a report type" }]}
          >
            <Select>
              <Option value="summary_age_analysis_mis">MIS Aging Report</Option>
              <Option value="summary_age_analysis_dom">DOM AND COM Aging Report</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="startDate"
            label="Start Date"
            rules={[{ required: true, message: "Please select a date" }]}
          >
            <DatePicker 
              style={{ width: "100%" }}
              disabledDate={(current) => current && current > moment().endOf('day')}
            />
          </Form.Item>

          <Form.Item
            name="endDate"
            label="End Date"
            dependencies={['startDate']}
            rules={[
              { required: true, message: "Please select a date" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || !getFieldValue('startDate') || 
                      value.isAfter(getFieldValue('startDate'))) {
                    return Promise.resolve();
                  }
                  return Promise.reject('End date must be after start date');
                },
              }),
            ]}
          >
            <DatePicker 
              style={{ width: "100%" }}
              disabledDate={(current) => {
                const startDate = form.getFieldValue('startDate');
                return (current && current < moment(startDate)) || 
                       current > moment().endOf('day');
              }}
            />
          </Form.Item>

          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[
              { required: true, message: "Please input your phone number" },
              {
                pattern: /^[0-9]{11,13}$/,
                message: "Please enter a valid phone number"
              }
            ]}
          >
            <Input placeholder="e.g., 08012345678" />
          </Form.Item>

          <Form.Item
            name="priority"
            label="Priority"
            rules={[{ required: true, message: "Please select priority" }]}
          >
            <Select>
              <Option value="high">High</Option>
              <Option value="medium">Medium</Option>
              <Option value="low">Low</Option>
            </Select>
          </Form.Item>

          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={loading}
              onClick={() => form.submit()}
            >
              {loading ? (
                <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                  <CircularProgress size={20} color="inherit" />
                  <span>Submitting...</span>
                </Box>
              ) : (
                "Submit Request"
              )}
            </Button>
          </Box>
        </Form>
      </CardContent>
    </Card>
  );
};

export default ReportRequestForm;