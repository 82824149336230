import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Spin, Col, Row, DatePicker, Button } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import moment from "moment";
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'
import { formatAmount } from "../../../helper/Formatter";

const { RangePicker } = DatePicker;

function ClaimAsOnDate() {

    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);

    const [fdateRange, setFdateRange] = useState([]);

    //  useEffect(async () => {
    //      setLoading(true)
    //     await onClickFilter();
    //  }, []);
    function categorizeMISPolicy(policyNumber, claim_type) {
        // Check if policy number starts with MIS
        if (policyNumber.startsWith('DOMG')) {
            return 'Domestic Insurance';
        }
        if (policyNumber.startsWith('COMG')) {
            return 'Commercial Insurance';
        }
    
        if (!policyNumber.startsWith('MIS')) {
            return null;
        }
      
        // Normalize claim type to handle case sensitivity and whitespace
        const normalizedClaimType = claim_type.trim().toLowerCase();
   
        // Categories based on the provided mapping
        if (normalizedClaimType === 'accident') {
            return 'Accidental Death';
        }
        
        if (normalizedClaimType === 'legal') {
            return 'Legal Insurance';
        }
        
        if (normalizedClaimType === 'all risk' || 
            normalizedClaimType === 'electronic equipment\'s' ||
            normalizedClaimType.includes('electronic')) {
            return 'Mobile & Electronic';
        }
        
        if (normalizedClaimType.includes('motor') ||
            normalizedClaimType.includes('glass') ||
             normalizedClaimType.includes('key loss') ||
             normalizedClaimType.includes('traders') ||
             normalizedClaimType.includes('internal') ||
             normalizedClaimType.includes('external')) {
            return 'Motor Comprehensive';
        }
        
        if (normalizedClaimType === 'motor') {
            return 'Third party';
        }
    
        // Return null if no matching category is found
        return null;
    }
    const options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        downloadOptions: { filename: "ClaimAsOnDate.csv", separator: "," },
        elevation: 6,
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // build the json, this is one way to do it
            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    if (columns[idx].name === 'Total Reserve' || columns[idx].name === 'Total Payment'
                        || columns[idx].name === 'Balance') {
                        temp[columns[idx].name] = parseFloat(v);
                    } else {
                        temp[columns[idx].name] = v;
                    }
                })
                result.push(temp)
                return result
            }, [])

            const fileName = `ClaimAsOnDate`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            // cancel default  CSV download from table
            return false
        }
    };

    const columns = [
        "Claim No",
        "Policy Number",
        "Claim Type",
        "Product Name",
        "Insured Name",
        // "CountyCode",
        "Date Of loss",
        "Claim Status",
        "Status Date",
        "Status Time",
        "Att. Involved",
        "PA Involved",
        "Short Desc.",
        "Reported Date",
        "MaxTrans",
        "Claim_SubStatus_na",
        "Transaction Type",
        "Trans Sub Type",
        "Total Reserve",
        "Total Payment",
        "Balance",
        "Loss Rpt Attach Y/N",
        "Salvage/SubrogationReserve",
        "Salvage/SubrogationPayment",
        "Claims Allocated To",
        "Regulatory Mapping"
    ];

    const onClickFilter = () => {
        const data = { fdateRange: fdateRange }
        setLoading(true)
        ReportAPI.getClaimAsOnDateReport(data)
            .then(res => {
                let data = [];

                res.data.records.forEach(report => {
                  try{
                    let claim_type = report.claim_type.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                        return letra.toUpperCase();
                    });
                    const product_name = categorizeMISPolicy(report.policyNumber, claim_type);
                    let customerName = report.customerName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                        return letra.toUpperCase();
                    });


                    let resData = [
                        report.claim_number,                
                        report.policyNumber,
                        claim_type,
                        product_name,
                        customerName,
                        // 'NA',
                        report.dateOfLoss !== null ? moment(report.dateOfLoss).format('DD-MM-YYYY') : 'NA',
                        report.claim_status !== null ? report.claim_status : 'NA',
                        report.id !== null ? moment(report.created_at).format('DD-MM-YYYY') : 'NA',
                        report.id !== null ? moment(report.created_at).format('HH:mm:ss') : 'NA',
                        'NA',
                        'NA',
                        report.claim_description !== null ? report.claim_description : '-',
                        report.date_reported_to_alpha !== null? moment(report.date_reported_to_alpha).format('DD-MM-YYYY') : 'NA',
                        'NA',
                        report.claim_sub_status !== null ? report.claim_sub_status : 'NA',
                        report.transactionType,
                        report.transSubType,
                        report.reserve_amt !== null ? report.reserve_amt : 0.00,
                        report.payment_amt !== null ? report.payment_amt : 0.00,
                        report.balance !=null? report.balance :0.00,
                        'NA',
                        'NA',
                        'NA',
                        report.claim_allocated_to !== null ? report.claim_allocated_to : 'NA',
                        report.regulatory_mapping !== null ? report.regulatory_mapping : 'NA'

                    ];
                    data = [resData, ...data]
                } catch (err) {
                    console.error("Error processing record:", err, report);
                }
                })
                setReports(data);
                setLoading(false);
            }).catch(error => {
                console.error("API Error:", error);
                setLoading(false);
            });
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label >Filter By date   </label>
                                <RangePicker allowClear
                                    format="YYYY-MM-DD"
                                    onChange={
                                        (value, dateString) => {
                                            value !== null ? setFdateRange(dateString) : setFdateRange([])
                                        }
                                    }
                                />
                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickFilter} className="btn btn-primary">Search</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <MUIDataTable
                        title={"Claim As On Date"}
                        data={reports}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}

export default ClaimAsOnDate
