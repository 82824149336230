import React from 'react';
import { Box, Typography, Grid, Divider } from '@material-ui/core';
import { Card } from 'antd';
import { makeStyles } from '@material-ui/core/styles';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import { AttachMoney, Share } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  card: {
    marginBottom: theme.spacing(3)
  },
  insightCard: {
    height: '100%'
  },
  chartContainer: {
    height: 400,
    marginTop: theme.spacing(2)
  },
  metricBox: {
    backgroundColor: '#f5f5f5',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  metricValue: {
    fontWeight: 500,
    fontSize: '1.2rem',
    color: theme.palette.primary.main
  },
  metricLabel: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem'
  },
  iconHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    '& svg': {
      marginRight: theme.spacing(1),
      color: theme.palette.primary.main
    }
  }
}));

const formatCurrency = (value) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'BWP',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(value);
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Box style={{ 
        backgroundColor: 'white', 
        padding: '10px', 
        border: '1px solid #ccc',
        borderRadius: '4px'
      }}>
        <Typography variant="body2"><strong>{label}</strong></Typography>
        {payload.map((entry, index) => (
          <Typography key={index} variant="body2" style={{ color: entry.color }}>
            {entry.name}: {entry.name.includes('Premium') 
              ? formatCurrency(entry.value)
              : entry.value.toFixed(2) + (entry.name.includes('Rate') ? '%' : '')}
          </Typography>
        ))}
      </Box>
    );
  }
  return null;
};

const ProductsTab = ({ products }) => {
    console.log(products)
  const classes = useStyles();
  
  const topProducts = products.find(p => p.type === 'product_performance')?.metrics?.top_products || [];
  const strugglingProducts = products.find(p => p.type === 'product_improvement')?.metrics?.struggling_products || [];
  const pricingData = products.find(p => p.type === 'product_pricing')?.metrics?.price_tiers || [];
  const distributionData = products.find(p => p.type === 'product_distribution')?.metrics?.distribution_metrics || [];

  const formatChartData = (data) => {
    return data.map(product => ({
      name: product.name.length > 20 ? product.name.substring(0, 20) + '...' : product.name,
      premium: product.premium,
      activationRate: product.activation_rate,
      avgPremium: product.avg_premium,
      uniqueAgents: product.unique_agents,
      ...(product.cancellation_rate !== undefined && { cancellationRate: product.cancellation_rate })
    }));
  };

  return (
    <Box className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card title="Top Performing Products" className={classes.card}>
            <Box className={classes.chartContainer}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={formatChartData(topProducts)}
                  margin={{ top: 20, right: 30, left: 20, bottom: 70 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis 
                    dataKey="name" 
                    angle={-45}
                    textAnchor="end"
                    height={80}
                  />
                  <YAxis 
                    yAxisId="left"
                    label={{ value: 'Premium (BWP)', angle: -90, position: 'insideLeft' }}
                  />
                  <YAxis 
                    yAxisId="right" 
                    orientation="right"
                    label={{ value: 'Activation Rate (%)', angle: 90, position: 'insideRight' }}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend />
                  <Bar 
                    yAxisId="left" 
                    dataKey="premium" 
                    fill="#2196F3" 
                    name="Premium (BWP)"
                  />
                  <Bar 
                    yAxisId="right" 
                    dataKey="activationRate" 
                    fill="#4CAF50" 
                    name="Activation Rate (%)"
                  />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card title="Products Needing Attention" className={classes.card}>
            <Box className={classes.chartContainer}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={formatChartData(strugglingProducts)}
                  margin={{ top: 20, right: 30, left: 20, bottom: 70 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis 
                    dataKey="name" 
                    angle={-45}
                    textAnchor="end"
                    height={80}
                  />
                  <YAxis 
                    yAxisId="left"
                    label={{ value: 'Premium (BWP)', angle: -90, position: 'insideLeft' }}
                  />
                  <YAxis 
                    yAxisId="right" 
                    orientation="right"
                    label={{ value: 'Cancellation Rate (%)', angle: 90, position: 'insideRight' }}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend />
                  <Bar 
                    yAxisId="left" 
                    dataKey="premium" 
                    fill="#FF9800" 
                    name="Premium (BWP)"
                  />
                  <Bar 
                    yAxisId="right" 
                    dataKey="cancellationRate" 
                    fill="#F44336" 
                    name="Cancellation Rate (%)"
                  />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>Additional Product Insights</Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card className={classes.insightCard}>
            <Box p={3}>
              <div className={classes.iconHeader}>
                <AttachMoney />
                <Typography variant="h6">Price Tier Analysis</Typography>
              </div>
              
              <Grid container spacing={2}>
                {pricingData.map((tier) => (
                  <Grid item xs={12} key={tier.tier}>
                    <Box className={classes.metricBox}>
                      <Typography variant="subtitle1" gutterBottom>
                        {tier.tier} Tier
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Typography className={classes.metricValue}>
                          {Math.round(tier.activation_rate)}%
                        
                          </Typography>
                          <Typography className={classes.metricLabel}>
                            Activation Rate
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography className={classes.metricValue}>
                            {tier.total_policies.toLocaleString()}
                          </Typography>
                          <Typography className={classes.metricLabel}>
                            Total Policies
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography className={classes.metricValue}>
                            {Math.round(tier.avg_agents)}
                          </Typography>
                          <Typography className={classes.metricLabel}>
                            Avg Agents
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card className={classes.insightCard}>
            <Box p={3}>
              <div className={classes.iconHeader}>
                <Share />
                <Typography variant="h6">Distribution Analysis</Typography>
              </div>

              <Box className={classes.chartContainer}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={distributionData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 70 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="product"
                      angle={-45}
                      textAnchor="end"
                      height={80}
                    />
                    <YAxis
                      yAxisId="left"
                      label={{ value: 'Policies per Agent', angle: -90, position: 'insideLeft' }}
                    />
                    <YAxis
                      yAxisId="right"
                      orientation="right"
                      label={{ value: 'Premium per Agent', angle: 90, position: 'insideRight' }}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                    <Bar
                      yAxisId="left"
                      dataKey="policies_per_agent"
                      fill="#8884d8"
                      name="Policies per Agent"
                    />
                    <Bar
                      yAxisId="right"
                      dataKey="premium_per_agent"
                      fill="#82ca9d"
                      name="Premium per Agent"
                    />
                  </BarChart>
                </ResponsiveContainer>
              </Box>

              <Divider style={{ margin: '20px 0' }} />

              <Typography variant="subtitle2" gutterBottom>
                Key Distribution Metrics
              </Typography>
              <Grid container spacing={2}>
                {distributionData.slice(0, 3).map((item) => (
                  <Grid item xs={12} key={item.product}>
                    <Box className={classes.metricBox}>
                      <Typography variant="subtitle2">
                        {item.product}
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography className={classes.metricValue}>
                            {item.agent_reach}
                          </Typography>
                          <Typography className={classes.metricLabel}>
                            Agent Reach
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography className={classes.metricValue}>
                            {formatCurrency(item.premium_per_agent)}
                          </Typography>
                          <Typography className={classes.metricLabel}>
                            Premium/Agent
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductsTab;