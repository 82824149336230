import { Card, CardContent, Typography } from "@material-ui/core";
import { Table, Tag, message } from "antd";
import { useState, useEffect } from "react";
import { ReportAPI } from "../../../apis/ReportAPI";
// Import your API function

const RequestQueue = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchRequests = async () => {
    try {
      setLoading(true);
      const response = await ReportAPI.getAllReportRequests();
      
      // Check if data exists and data is an array
      if (response?.data?.data) {
        const dataArray = Array.isArray(response.data.data) 
        ? response.data.data 
        : [response.data.data];
        // Since response.data.data is an Object, not an array, wrap it in an array
        const formattedRequests = dataArray.map(request => ({
          id: request.id,
          type: request.type,
          startDate: request.start_date,
          endDate: request.end_date,
          priority: request.priority?.charAt(0).toUpperCase() + request.priority?.slice(1) || '',
          status: request.status?.charAt(0).toUpperCase() + request.status?.slice(1) || 'Pending',
        }));
        
        setRequests(formattedRequests);
      }
    } catch (error) {
      console.error('Error fetching requests:', error);
      message.error('Failed to load requests');
    } finally {
      setLoading(false);
    }
  };
  console.log(requests)
  useEffect(() => {
    fetchRequests();
    // Refresh data every 30 seconds
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type) => {
        const typeMap = {
          'summary_age_analysis_mis': 'MIS Aging Report'
        };
        return typeMap[type] || type;
      }
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (date) => new Date(date).toLocaleDateString()
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (date) => new Date(date).toLocaleDateString()
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (priority) => (
        <Tag
          color={
            priority.toLowerCase() === "high"
              ? "red"
              : priority.toLowerCase() === "medium"
              ? "orange"
              : "green"
          }
        >
          {priority}
        </Tag>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag
          color={
            status.toLowerCase() === "processing"
              ? "blue"
              : status.toLowerCase() === "completed"
              ? "green"
              : "gold"
          }
        >
          {status}
        </Tag>
      ),
    },
  ];

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Request Queue
        </Typography>
        <Table
          columns={columns}
          dataSource={requests}
          rowKey="id"
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} requests`
          }}
          loading={loading}
        />
      </CardContent>
    </Card>
  );
};

export default RequestQueue;