import axios from "axios";
import { Config } from "../Config";
import { getMainCountry, Token } from "../services/Token";

export const ReportAPI = {
    fetchProducts: async (reportName, countryCode) => {
        return await axios.get(`${Config.apiNodeURL}/fetch-products?reportName=${reportName}&db_select=${countryCode}`);
    },
    fetchDashboardData: async (countryCode) => {
        return await axios.get(`${Config.apiNodeURL}/fetch-dashboard-data?db_select=${countryCode}`);
    },
    qoutesFilters: async (countryCode) => {
        return await axios.get(`${Config.apiNodeURL}/get-qoute-reports-filters?db_select=${countryCode}`);
    },
    qoutes: async (data) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };
        return await fetch(`${Config.apiNodeURL}/get-qoute-reports?db_select=${data.countryCode}`, requestOptions)
            .then(response => response.json());
        // return await axios.post(`${Config.apiNodeURL}/get-qoute-reports`, data);
    },
    qouteExportExcel: async (data) => {
        data = { ...data, user_id: Token.getUserID() }
        return await axios.post(`${Config.apiNodeURL}/quote-reports-export-excel?db_select=${data.countryCode}`, data);
    },
    fetchFilterDataForReport: async (countryCode, userRole ) => {
        return await axios.get(`${Config.apiNodeURL}/fetch-policy-filters?db_select=${countryCode}`,{
            params: {
                userRole: userRole
            }
        });
    },
    fetchFilterPolicyReports: async (data) => {

        data = {
            ...data,
            mainCountry: getMainCountry(),
            user_id: Token.getUserID(),
            user_role: Token.getUserRole(),
            agency_id: Token.getAgencyID(),
            access_all: Token.getAccessAll()
        };

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };
        return await fetch(`${Config.apiNodeURL}/fetch-filter-policies?db_select=${data.countryCode}`, requestOptions)
            .then(response => response.json());

        // return await axios.post(`${Config.apiNodeURL}/fetch-filter-policies`, data);
    },

    // fetchFilterWrittenPremiumReports: async (data) => {
    //     return await axios.post(`${Config.apiNodeURL}/fetch-filter-written-premium-reports`, data);
    // },
    fetchFilterMotorComprehensiveReports: async (data) => {
        data = {
            ...data,
            mainCountry: getMainCountry(),
            user_id: Token.getUserID(),
            user_role: Token.getUserRole(),
            agency_id: Token.getAgencyID(),
            access_all: Token.getAccessAll()
        };

        return await axios.post(`${Config.apiNodeURL}/fetch-filter-motor-comprehensive-reports?db_select=${data.countryCode}`, data);
    },
    fetchFilterEarnedPremiumWithUnearnedReports: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-filter-earned-premium-with-unearned-reports`, data);
    },
    fetchFilterBookOfBusinessReports: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-filter-book-of-business-reports`, data);
    },
    getUserSales: async (countryCode) => {
        return await axios.get(`${Config.apiNodeURL}/fetch-user-sales?db_select=${countryCode}`);
    },
    getDetaildAgeAnalysis: async () => {
        return await axios.get(`${Config.apiNodeURL}/fetch-detaild-age-analysis`);
    },
    getSummaryAgeAnalysis: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-summary-age-analysis`, data);
    },
    generateSummaryAgeAnalysis: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/generate-summary-age-analysis`, data);
    },
    fetchFilterTransactionReportByProduct: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-filter-transaction-report-by-product?db_select=${data.countryCode}`, data);
    },

    getActivationCancelledPolicyReport: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-activated-cancelled-policy-report`, data);
    },
    getTransactionReportsFilters: async () => {
        return await axios.get(`${Config.apiNodeURL}/fetch-transaction-reports-filters`);
    },
    getTransactionReport: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-filter-transaction-reports`, data);
    },
    getTransactionReportRealpay: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-transaction-reports-realpay`, data);
    },
    getFailedTransactionReportFilters: async () => {
        return await axios.get(`${Config.apiNodeURL}/fetch-failed-transaction-report-filters`);
    },
    getFailedTransactionReport: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-filter-failed-transaction-reports`, data);
    },
    getFetchClaimReport: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-claim-reports?db_select=${data.countryCode}`, data);
    },
    getUserReportLists: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-user-report-lists`, data);
    },
    getReInsuranceRiskProfile: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-re-insurance-risk-profile`, data);
    },
    getReInsuranceRiskProfileSummary: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-re-insurance-risk-profile-summary`, data);
    },
    getReInsuranceClaimsProfile: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-re-insurance-claims-profile`, data);
    },
    getPolicyStatusReport: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-policy-status-report`, data);
    },
    getTransactionSummaryProductReport: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-transaction-summary-product-report`, data);
    },
    getTransactionReportByBookingDate: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-transaction-report-by-booking-date`, data);
    },
    getAgentReports: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-agent-reports`, data);
    },
    getCustomerReports: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-customer-reports`, data);
    },
    getAnniversaryDateReport: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-anniversary-sate-reports`, data);
    },
    getClaimAsOnDateReport: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-claim-as-on-date-report`, data);
    },
    getClaimBordereaux: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-claim-bordereaux-report`, data);
    },
    getClaimBordereauxOutstandingPayment: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-claim-bordereaux-outstanding-payment`, data);
    },
    getClaimCoverageAllocationReport: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-claim-coverage-allocation-report`, data);
    },
    getVehiclePreinspctionReport: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-vehicle-reinspction-report`, data);
    },
    getCustomerKYCReport: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-customer-KYC-report?db_select=${data.countryCode}`, data);
    },
    getCustomerKYCReportV2: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-customer-KYC-report-v2`, data);
    },
    getCustomerKYCReportV3: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-customer-KYC-report-v3`, data);
    },
    getCustomerKYCReportV4: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-customer-KYC-report-v4`, data);
    },
    getKYCReportActivatedPolicies: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-customer-KYC-activated-policies`, data);
    },
    getAgentsForVirtualBoxReport: async () => {
        return await axios.get(`${Config.apiNodeURL}/fetch-agents-for-virtual-box-report`);
    },
    getVirtualBoxReport: async (data) => {
        data = {
            ...data,
            mainCountry: getMainCountry(),
            user_id: Token.getUserID(),
            user_role: Token.getUserRole(),
            agency_id: Token.getAgencyID(),
            access_all: Token.getAccessAll()
        };

        return await axios.post(`${Config.apiNodeURL}/fetch-virtual-box-report`, data);
    },
    getAgentLoginReport: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-agent-login-report`, data);
    },
    getAgentLoginActiveIncativeReport: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-agent-login-active-incative-report`, data);
    },
    getPaymentReport: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-payment-report`, data);
    },
    getAgentPolicyReport: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-agent-policy-report`, data);
    },
    getStorePolicyReport: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-store-policy-report`, data);
    },
    getCustomerKYCIDReport: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-customer-kyc-id-report`, data);
    },
    getShowCashTransactions: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-show-cash-transactions`, data);
    },
    getOrangeMoneyReport: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-orange-money-report`, data);
    },
    getCellphoneDevicePreInspectionReports: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-cellphone-device-preinspection-reports?db_select=${data.countryCode}`, data);
    },
    getCardExpiryReport: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-card-expiry-report`, data);
    },
    getPaymentTransaction: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-payment-transaction-report?db_select=${data.countryCode}`, data);
    },
    fetchPaymentTransactionSettlement: async (type) => {
        return await axios.get(`${Config.apiNodeURL}/get-payment-transaction-settlement-files?pay_type=${type}`);
    },
    importRecurringPaymentTransactionSettlement: async (data) => {
        const finaldata = {
            user_id: Token.getUserID(),
            data: data
        }
        return await axios.post(`${Config.apiNodeURL}/import-recurring-payment-transaction-settlement-xlsx`, finaldata);
    },
    importFirstPaymentTransactionSettlement: async (data) => {
        const finaldata = {
            user_id: Token.getUserID(),
            data: data
        }
        return await axios.post(`${Config.apiNodeURL}/import-first-payment-transaction-settlement-xlsx`, finaldata);
    },
    getAgentLoginReportV2: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-agent-login-report-v2`, data);
    },
    getUserReportListsV2: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-user-report-lists-v2`, data);
    },

    fetchFilterNewWrittenPremiumReports: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-filter-new-written-premium-reports`, data);
    },
    getMonthlyCollections: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-monthly-collections`, data);
    },
    getCustomerAllKYCDocumentsReport: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-customer-all-kyc-documents-report`, data);
    },
    getRenewalPolicyList: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-renewal-policy-list`, data);
    },
    getPolicyCollectionReport: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/policy-collection-report`, data);
    },
    getCustomerDuplicateEmailReports: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-duplicate-customer-email-reports`, data);
    },
    getRevioUpload: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-revio-upload`, data);
    },
    getMonthlyCollectionProductWiseReports: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-monthly-collection-product-wise-reports`, data);
    },
    getMonthlyCollectionPaymentWiseReports: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-monthly-collection-payment-wise-reports`, data);
    },
    getActivatedPoliciesKYCPendingReport: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-activated-policies-kyc-pending-report`, data);
    },
    getBlacklistCustomerReport: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-blacklist-customer-report`, data);
    },
    getPreDownloadedReport: async (data) => {
        return await axios.get(`${Config.apiNodeURL}/fetch-pre-downloaded-report?is_generated_uploaded=${data.type}&db_select=${data.countryCode}`);
    },
    uploadFiles: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/upload-files`, data);
    },
    selectDB: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/select_db_env`, data)
    },
    getAllReportRequests: async(data)=>{
        return await axios.get(`${Config.apiNodeURL}/get-requests`, data);
    },
    mutateReportRequest: async(data)=>{
        return await axios.post(`${Config.apiNodeURL}/request-report`, data);
    },
    getSAPremiumReport: async(data)=>{
        return await axios.post(`${Config.apiNodeURL}/get-sa-premium-report`, data);
    },
    getSAInforceReport: async(data)=>{
        return await axios.post(`${Config.apiNodeURL}/get-sa-inforce-report`, data);
    },
    getSANewBizReport: async(data)=>{
        return await axios.post(`${Config.apiNodeURL}/get-sa-newbiz-report`, data);
    },
    getSARiskReport: async(data)=>{
        return await axios.post(`${Config.apiNodeURL}/get-sa-risk-report`, data);
    },
    getSACancelationReport: async(data)=>{
        return await axios.post(`${Config.apiNodeURL}/get-sa-cancelation-report`, data);
    },
    fetchDashboardV2Data: async () => {
        return await axios.get(`${Config.apiNodeURL}/get-revenue-data`);
    },
    fetchFraudData: async () => {
        return await axios.get(`${Config.apiNodeURL}/get-fraud-data`);
    },
}
