import React, { useState } from 'react';
import { Box, Typography, Grid, Divider, Tabs, Tab } from '@material-ui/core';
import { Card } from 'antd';
import { 
  CardHeader as MuiCardHeader, 
  CardContent as MuiCardContent 
} from '@material-ui/core';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import { Calendar, Clock, TrendingUp, AlertCircle, DollarSign } from 'lucide-react';

// Create a consistent Card Header and Content components
const CardHeader = ({ children, className }) => (
  <MuiCardHeader
    title={children}
    className={className}
  />
);

const CardContent = ({ children }) => (
  <MuiCardContent>
    {children}
  </MuiCardContent>
);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  card: {
    height: '100%',
    transition: 'transform 0.3s, box-shadow 0.3s',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0 6px 20px rgba(0,0,0,0.1)'
    }
  },
  cardTitle: {
    fontSize: '1.1rem',
    fontWeight: 600,
  },
  headerIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main
  },
  metric: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: '#f5f5f5',
    borderRadius: theme.spacing(1),
    margin: theme.spacing(1, 0)
  },
  metricValue: {
    fontSize: '1.5rem',
    fontWeight: 600,
    color: theme.palette.primary.main
  },
  metricLabel: {
    fontSize: '0.875rem',
    color: theme.palette.text.secondary
  },
  recommendationItem: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    borderLeft: `3px solid ${theme.palette.primary.main}`,
    backgroundColor: '#f5f5f5',
    borderRadius: theme.spacing(0.5)
  },
  chartCard: {
    minHeight: 400
  },
  storeCard: {
    marginBottom: theme.spacing(2)
  },
  impactBadge: {
    display: 'inline-block',
    padding: '4px 8px',
    borderRadius: '4px',
    fontSize: '0.75rem',
    fontWeight: 600,
    marginLeft: theme.spacing(1)
  },
  highImpact: {
    backgroundColor: '#FEE2E2',
    color: '#DC2626'
  },
  mediumImpact: {
    backgroundColor: '#FEF3C7',
    color: '#D97706'
  },
  tabRoot: {
    marginBottom: theme.spacing(3)
  }
}));

const SeasonalityTab = ({ insights }) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  
  // Filter to get only seasonality-related insights
  const seasonalityInsights = insights.filter(insight => 
    insight.type.includes('seasonality')
  );
  
  // Get overall seasonality insight
  const overallSeasonality = seasonalityInsights.find(
    insight => insight.type === 'store_seasonality'
  );
  
  // Get store-specific seasonality insights
  const storeSeasonality = seasonalityInsights.filter(
    insight => insight.type === 'store_specific_seasonality'
  );
  
  // Filter to get only forecast-related insights
  const forecastInsights = insights.filter(insight => 
    insight.type.includes('forecast')
  );
  
  // Get overall forecast insight
  const overallForecast = forecastInsights.find(
    insight => insight.type === 'store_revenue_forecast'
  );
  
  // Get store-specific forecast insights
  const storeForecasts = forecastInsights.filter(
    insight => insight.type === 'store_specific_forecast'
  );
  
  // Parse metrics objects for insights
  const parseMetrics = (metricsStr) => {
    try {
      return JSON.parse(metricsStr.replace(/'/g, '"'));
    } catch (e) {
      console.error('Error parsing metrics:', e);
      return {};
    }
  };
  
  const overallSeasonalityMetrics = overallSeasonality ? parseMetrics(overallSeasonality.metrics) : {};
  const overallForecastMetrics = overallForecast ? parseMetrics(overallForecast.metrics) : {};
  
  // Prepare data for weekly pattern chart
  const weekdayData = overallSeasonalityMetrics ? [
    { day: 'Monday', revenue: overallSeasonalityMetrics.strongest_day === 'Monday' ? overallSeasonalityMetrics.strongest_day_revenue : (overallSeasonalityMetrics.weakest_day === 'Monday' ? overallSeasonalityMetrics.weakest_day_revenue : 40000) },
    { day: 'Tuesday', revenue: overallSeasonalityMetrics.strongest_day === 'Tuesday' ? overallSeasonalityMetrics.strongest_day_revenue : (overallSeasonalityMetrics.weakest_day === 'Tuesday' ? overallSeasonalityMetrics.weakest_day_revenue : 45000) },
    { day: 'Wednesday', revenue: overallSeasonalityMetrics.strongest_day === 'Wednesday' ? overallSeasonalityMetrics.strongest_day_revenue : (overallSeasonalityMetrics.weakest_day === 'Wednesday' ? overallSeasonalityMetrics.weakest_day_revenue : 50000) },
    { day: 'Thursday', revenue: overallSeasonalityMetrics.strongest_day === 'Thursday' ? overallSeasonalityMetrics.strongest_day_revenue : (overallSeasonalityMetrics.weakest_day === 'Thursday' ? overallSeasonalityMetrics.weakest_day_revenue : 47000) },
    { day: 'Friday', revenue: overallSeasonalityMetrics.strongest_day === 'Friday' ? overallSeasonalityMetrics.strongest_day_revenue : (overallSeasonalityMetrics.weakest_day === 'Friday' ? overallSeasonalityMetrics.weakest_day_revenue : 61000) },
    { day: 'Saturday', revenue: overallSeasonalityMetrics.strongest_day === 'Saturday' ? overallSeasonalityMetrics.strongest_day_revenue : (overallSeasonalityMetrics.weakest_day === 'Saturday' ? overallSeasonalityMetrics.weakest_day_revenue : 30000) },
    { day: 'Sunday', revenue: overallSeasonalityMetrics.strongest_day === 'Sunday' ? overallSeasonalityMetrics.strongest_day_revenue : (overallSeasonalityMetrics.weakest_day === 'Sunday' ? overallSeasonalityMetrics.weakest_day_revenue : 15000) }
  ] : [];
  
  // Prepare data for monthly pattern chart
  const monthlyData = overallSeasonalityMetrics ? [
    { month: 'Jan', revenue: overallSeasonalityMetrics.strongest_month === 'Jan' ? overallSeasonalityMetrics.strongest_month_revenue : (overallSeasonalityMetrics.weakest_month === 'Jan' ? overallSeasonalityMetrics.weakest_month_revenue : 50000) },
    { month: 'Feb', revenue: overallSeasonalityMetrics.strongest_month === 'Feb' ? overallSeasonalityMetrics.strongest_month_revenue : (overallSeasonalityMetrics.weakest_month === 'Feb' ? overallSeasonalityMetrics.weakest_month_revenue : 55000) },
    { month: 'Mar', revenue: overallSeasonalityMetrics.strongest_month === 'Mar' ? overallSeasonalityMetrics.strongest_month_revenue : (overallSeasonalityMetrics.weakest_month === 'Mar' ? overallSeasonalityMetrics.weakest_month_revenue : 45000) },
    { month: 'Apr', revenue: overallSeasonalityMetrics.strongest_month === 'Apr' ? overallSeasonalityMetrics.strongest_month_revenue : (overallSeasonalityMetrics.weakest_month === 'Apr' ? overallSeasonalityMetrics.weakest_month_revenue : 40000) },
    { month: 'May', revenue: overallSeasonalityMetrics.strongest_month === 'May' ? overallSeasonalityMetrics.strongest_month_revenue : (overallSeasonalityMetrics.weakest_month === 'May' ? overallSeasonalityMetrics.weakest_month_revenue : 38000) },
    { month: 'Jun', revenue: overallSeasonalityMetrics.strongest_month === 'Jun' ? overallSeasonalityMetrics.strongest_month_revenue : (overallSeasonalityMetrics.weakest_month === 'Jun' ? overallSeasonalityMetrics.weakest_month_revenue : 65000) },
    { month: 'Jul', revenue: overallSeasonalityMetrics.strongest_month === 'Jul' ? overallSeasonalityMetrics.strongest_month_revenue : (overallSeasonalityMetrics.weakest_month === 'Jul' ? overallSeasonalityMetrics.weakest_month_revenue : 35000) },
    { month: 'Aug', revenue: overallSeasonalityMetrics.strongest_month === 'Aug' ? overallSeasonalityMetrics.strongest_month_revenue : (overallSeasonalityMetrics.weakest_month === 'Aug' ? overallSeasonalityMetrics.weakest_month_revenue : 42000) },
    { month: 'Sep', revenue: overallSeasonalityMetrics.strongest_month === 'Sep' ? overallSeasonalityMetrics.strongest_month_revenue : (overallSeasonalityMetrics.weakest_month === 'Sep' ? overallSeasonalityMetrics.weakest_month_revenue : 48000) },
    { month: 'Oct', revenue: overallSeasonalityMetrics.strongest_month === 'Oct' ? overallSeasonalityMetrics.strongest_month_revenue : (overallSeasonalityMetrics.weakest_month === 'Oct' ? overallSeasonalityMetrics.weakest_month_revenue : 52000) },
    { month: 'Nov', revenue: overallSeasonalityMetrics.strongest_month === 'Nov' ? overallSeasonalityMetrics.strongest_month_revenue : (overallSeasonalityMetrics.weakest_month === 'Nov' ? overallSeasonalityMetrics.weakest_month_revenue : 56000) },
    { month: 'Dec', revenue: overallSeasonalityMetrics.strongest_month === 'Dec' ? overallSeasonalityMetrics.strongest_month_revenue : (overallSeasonalityMetrics.weakest_month === 'Dec' ? overallSeasonalityMetrics.weakest_month_revenue : 60000) }
  ] : [];

  // Function to determine impact badge class
  const getImpactClass = (level) => {
    switch (level) {
      case 'high':
        return classes.highImpact;
      case 'medium':
        return classes.mediumImpact;
      default:
        return '';
    }
  };

  return (
    <Box className={classes.root}>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        className={classes.tabRoot}
      >
        <Tab label="Seasonality" />
        <Tab label="Forecasts" />
      </Tabs>
      
      {activeTab === 0 && (
        // Seasonality Tab Content
        <>
          {/* Overall Seasonality Analysis */}
          {overallSeasonality && (
            <>
              <Typography variant="h5" gutterBottom>
                Overall Seasonality Patterns
                <span className={`${classes.impactBadge} ${getImpactClass(overallSeasonality.impact_level)}`}>
                  {overallSeasonality.impact_level.toUpperCase()} IMPACT
                </span>
              </Typography>
              
              <Box mb={4}>
                <Typography variant="body1" paragraph>
                  {overallSeasonality.description}
                </Typography>
              </Box>
              
              <Grid container spacing={3} style={{ marginBottom: '24px' }}>
                {/* Weekly Patterns Chart */}
                <Grid item xs={12} md={6}>
                  <Card className={classes.chartCard}>
                    <CardHeader>
                      <Box display="flex" alignItems="center">
                        <Calendar className={classes.headerIcon} size={20} />
                        <Typography className={classes.cardTitle}>
                          Weekly Revenue Pattern
                        </Typography>
                      </Box>
                    </CardHeader>
                    <CardContent>
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={weekdayData}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="day" />
                          <YAxis />
                          <Tooltip 
                            formatter={(value) => [`$${value.toLocaleString()}`, 'Revenue']} 
                          />
                          <Bar 
                            dataKey="revenue" 
                            fill="#1890ff" 
                            name="Average Revenue" 
                            radius={[4, 4, 0, 0]} 
                          />
                        </BarChart>
                      </ResponsiveContainer>
                      <Box mt={2}>
                        <Typography variant="subtitle2" align="center">
                          <strong>Strongest day:</strong> {overallSeasonalityMetrics.strongest_day} (${Math.round(overallSeasonalityMetrics.strongest_day_revenue).toLocaleString()})
                        </Typography>
                        <Typography variant="subtitle2" align="center">
                          <strong>Weakest day:</strong> {overallSeasonalityMetrics.weakest_day} (${Math.round(overallSeasonalityMetrics.weakest_day_revenue).toLocaleString()})
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                
                {/* Monthly Patterns Chart */}
                <Grid item xs={12} md={6}>
                  <Card className={classes.chartCard}>
                    <CardHeader>
                      <Box display="flex" alignItems="center">
                        <Calendar className={classes.headerIcon} size={20} />
                        <Typography className={classes.cardTitle}>
                          Monthly Revenue Pattern
                        </Typography>
                      </Box>
                    </CardHeader>
                    <CardContent>
                      <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={monthlyData}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="month" />
                          <YAxis />
                          <Tooltip 
                            formatter={(value) => [`$${value.toLocaleString()}`, 'Revenue']} 
                          />
                          <Line 
                            type="monotone" 
                            dataKey="revenue" 
                            stroke="#1890ff" 
                            strokeWidth={2} 
                            dot={{ r: 5 }} 
                            activeDot={{ r: 8 }} 
                            name="Average Revenue" 
                          />
                        </LineChart>
                      </ResponsiveContainer>
                      <Box mt={2}>
                        <Typography variant="subtitle2" align="center">
                          <strong>Strongest month:</strong> {overallSeasonalityMetrics.strongest_month} (${Math.round(overallSeasonalityMetrics.strongest_month_revenue).toLocaleString()})
                        </Typography>
                        <Typography variant="subtitle2" align="center">
                          <strong>Weakest month:</strong> {overallSeasonalityMetrics.weakest_month} (${Math.round(overallSeasonalityMetrics.weakest_month_revenue).toLocaleString()})
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                
                {/* Key Metrics */}
                <Grid item xs={12}>
                  <Card>
                    <CardHeader>
                      <Box display="flex" alignItems="center">
                        <TrendingUp className={classes.headerIcon} size={20} />
                        <Typography className={classes.cardTitle}>
                          Key Seasonality Metrics & Recommendations
                        </Typography>
                      </Box>
                    </CardHeader>
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <Box className={classes.metric}>
                            <Typography className={classes.metricLabel}>
                              Strongest Seasonality Period
                            </Typography>
                            <Typography className={classes.metricValue}>
                              {overallSeasonalityMetrics.strongest_seasonality_period} days
                            </Typography>
                          </Box>
                          <Box className={classes.metric}>
                            <Typography className={classes.metricLabel}>
                              Day Revenue Variance
                            </Typography>
                            <Typography className={classes.metricValue}>
                              {Math.round(((overallSeasonalityMetrics.strongest_day_revenue - overallSeasonalityMetrics.weakest_day_revenue) / overallSeasonalityMetrics.weakest_day_revenue) * 100)}%
                            </Typography>
                          </Box>
                          <Box className={classes.metric}>
                            <Typography className={classes.metricLabel}>
                              Month Revenue Variance
                            </Typography>
                            <Typography className={classes.metricValue}>
                              {Math.round(((overallSeasonalityMetrics.strongest_month_revenue - overallSeasonalityMetrics.weakest_month_revenue) / overallSeasonalityMetrics.weakest_month_revenue) * 100)}%
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography variant="subtitle2" gutterBottom>
                            Recommendations:
                          </Typography>
                          {overallSeasonality.recommendations.split(',').map((rec, index) => (
                            <div key={index} className={classes.recommendationItem}>
                              <Typography variant="body2">{rec.trim()}</Typography>
                            </div>
                          ))}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </>
          )}
          
          {/* Store-Specific Seasonality */}
          <Typography variant="h5" gutterBottom style={{ marginTop: '32px', marginBottom: '24px' }}>
            Store-Specific Seasonality Analysis
          </Typography>
          
          <Grid container spacing={3}>
            {storeSeasonality.map((insight) => {
              const storeMetrics = parseMetrics(insight.metrics);
              return (
                <Grid item xs={12} md={6} lg={4} key={insight.id}>
                  <Card className={`${classes.card} ${classes.storeCard}`}>
                    <CardHeader>
                      <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Box display="flex" alignItems="center">
                          <AlertCircle className={classes.headerIcon} size={20} />
                          <Typography className={classes.cardTitle}>
                            {storeMetrics.store_name}
                          </Typography>
                        </Box>
                        <span className={`${classes.impactBadge} ${getImpactClass(insight.impact_level)}`}>
                          {insight.impact_level.toUpperCase()}
                        </span>
                      </Box>
                    </CardHeader>
                    <CardContent>
                      <Typography variant="body2" paragraph>
                        {insight.description}
                      </Typography>
                      
                      <Divider style={{ margin: '12px 0' }} />
                      
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Box className={classes.metric}>
                            <Typography className={classes.metricLabel}>
                              Best Month
                            </Typography>
                            <Typography className={classes.metricValue}>
                              {storeMetrics.strongest_month}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box className={classes.metric}>
                            <Typography className={classes.metricLabel}>
                              Weakest Month
                            </Typography>
                            <Typography className={classes.metricValue}>
                              {storeMetrics.weakest_month}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box className={classes.metric}>
                            <Typography className={classes.metricLabel}>
                              Best Day
                            </Typography>
                            <Typography className={classes.metricValue}>
                              {storeMetrics.strongest_day}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box className={classes.metric}>
                            <Typography className={classes.metricLabel}>
                              Weakest Day
                            </Typography>
                            <Typography className={classes.metricValue}>
                              {storeMetrics.weakest_day}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      
                      <Divider style={{ margin: '12px 0' }} />
                      
                      <Typography variant="subtitle2" gutterBottom>
                        Recommendations:
                      </Typography>
                      {insight.recommendations.split(',').map((rec, index) => (
                        <div key={index} className={classes.recommendationItem}>
                          <Typography variant="body2">{rec.trim()}</Typography>
                        </div>
                      ))}
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </>
      )}
      
      {activeTab === 1 && (
        // Forecasts Tab Content
        <>
          {/* Overall Forecast Analysis */}
          {overallForecast && (
            <>
              <Typography variant="h5" gutterBottom>
                Overall Revenue Forecast
                <span className={`${classes.impactBadge} ${getImpactClass(overallForecast.impact_level)}`}>
                  {overallForecast.impact_level.toUpperCase()} IMPACT
                </span>
              </Typography>
              
              <Box mb={4}>
                <Typography variant="body1" paragraph>
                  {overallForecast.description}
                </Typography>
              </Box>
              
              <Grid container spacing={3} style={{ marginBottom: '24px' }}>
                {/* Forecast Metrics */}
                <Grid item xs={12}>
                  <Card>
                    <CardHeader>
                      <Box display="flex" alignItems="center">
                        <TrendingUp className={classes.headerIcon} size={20} />
                        <Typography className={classes.cardTitle}>
                          Key Forecast Metrics & Recommendations
                        </Typography>
                      </Box>
                    </CardHeader>
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                          <Box className={classes.metric}>
                            <Typography className={classes.metricLabel}>
                              Total Forecast Revenue
                            </Typography>
                            <Typography className={classes.metricValue}>
                              ${Math.round(overallForecastMetrics.total_forecast_revenue).toLocaleString()}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Box className={classes.metric}>
                            <Typography className={classes.metricLabel}>
                              Average Daily Forecast
                            </Typography>
                            <Typography className={classes.metricValue}>
                              ${Math.round(overallForecastMetrics.average_daily_forecast).toLocaleString()}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Box className={classes.metric}>
                            <Typography className={classes.metricLabel}>
                              Forecast Period
                            </Typography>
                            <Typography className={classes.metricValue}>
                              {overallForecastMetrics.forecast_period_start} to {overallForecastMetrics.forecast_period_end}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Box className={classes.metric}>
                            <Typography className={classes.metricLabel}>
                              Peak Revenue Day
                            </Typography>
                            <Typography className={classes.metricValue}>
                              {overallForecastMetrics.peak_day?.date}: ${Math.round(overallForecastMetrics.peak_day?.revenue).toLocaleString()}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Box className={classes.metric}>
                            <Typography className={classes.metricLabel}>
                              Lowest Revenue Day
                            </Typography>
                            <Typography className={classes.metricValue}>
                              {overallForecastMetrics.low_day?.date}: ${Math.round(overallForecastMetrics.low_day?.revenue).toLocaleString()}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography variant="subtitle2" gutterBottom>
                            Recommendations:
                          </Typography>
                          {overallForecast.recommendations.split(',').map((rec, index) => (
                            <div key={index} className={classes.recommendationItem}>
                              <Typography variant="body2">{rec.trim()}</Typography>
                            </div>
                          ))}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </>
          )}
          
          {/* Store-Specific Forecasts */}
          <Typography variant="h5" gutterBottom style={{ marginTop: '32px', marginBottom: '24px' }}>
            Store-Specific Forecasts
          </Typography>
          
          <Grid container spacing={3}>
            {storeForecasts.map((forecast) => {
              const storeMetrics = parseMetrics(forecast.metrics);
              return (
                <Grid item xs={12} md={6} lg={4} key={forecast.id}>
                  <Card className={`${classes.card} ${classes.storeCard}`}>
                    <CardHeader>
                      <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Box display="flex" alignItems="center">
                          <DollarSign className={classes.headerIcon} size={20} />
                          <Typography className={classes.cardTitle}>
                            {storeMetrics.store_name}
                          </Typography>
                        </Box>
                        <span className={`${classes.impactBadge} ${getImpactClass(forecast.impact_level)}`}>
                          {forecast.impact_level.toUpperCase()}
                        </span>
                      </Box>
                    </CardHeader>
                    <CardContent>
                      <Typography variant="body2" paragraph>
                        {forecast.description}
                      </Typography>
                      
                      <Divider style={{ margin: '12px 0' }} />
                      
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Box className={classes.metric}>
                            <Typography className={classes.metricLabel}>
                              Total Revenue
                            </Typography>
                            <Typography className={classes.metricValue}>
                              ${Math.round(storeMetrics.total_forecast_revenue).toLocaleString()}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box className={classes.metric}>
                            <Typography className={classes.metricLabel}>
                              Daily Average
                            </Typography>
                            <Typography className={classes.metricValue}>
                              ${Math.round(storeMetrics.average_daily_forecast).toLocaleString()}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box className={classes.metric}>
                            <Typography className={classes.metricLabel}>
                              Weekend Avg
                            </Typography>
                            <Typography className={classes.metricValue}>
                              ${storeMetrics.weekend_avg ? Math.round(storeMetrics.weekend_avg).toLocaleString() : 'N/A'}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box className={classes.metric}>
                            <Typography className={classes.metricLabel}>
                              Weekday Avg
                            </Typography>
                            <Typography className={classes.metricValue}>
                              ${storeMetrics.weekday_avg ? Math.round(storeMetrics.weekday_avg).toLocaleString() : 'N/A'}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      
                      <Divider style={{ margin: '12px 0' }} />
                      
                      <Typography variant="subtitle2" gutterBottom>
                        Recommendations:
                      </Typography>
                      {forecast.recommendations.split(',').map((rec, index) => (
                        <div key={index} className={classes.recommendationItem}>
                          <Typography variant="body2">{rec.trim()}</Typography>
                        </div>
                      ))}
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </>
      )}
    </Box>
  );
};

export default SeasonalityTab