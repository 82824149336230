import React, { Fragment, useState } from "react";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { formatAmount } from "../../../helper/Formatter";
import { Spin, Col, Row, DatePicker, Button } from "antd";
import { saveAs } from "file-saver";
import { writeFile, utils } from "xlsx";
import { ReportAPI } from "../../../apis/ReportAPI";

const { RangePicker } = DatePicker;

export default function NewWrittenPremiumReport() {
    const [loading, setLoading] = useState(false);
    const [policies, setPolicies] = useState([]);
    const [fdateRange, setFdateRange] = useState([]);

    // Helper function to format numbers consistently
    const formatNumber = (value) => {
        if (value === null || value === undefined || value === "") return 0;
        const num = typeof value === 'string' ? parseFloat(value) : value;
        return isNaN(num) ? 0 : Number(num.toFixed(2));
    };

    const options = {
        selectableRows: "none",
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        download: false,
        elevation: 6,
    };

    const columns = [
        { name: "policyNumber", label: "Policy No." },
        { 
            name: "termStartDate", 
            label: "Term Start", 
            options: { customBodyRender: (value) => value ? moment(value).format("DD-MM-YYYY") : "N/A" }
        },
        { 
            name: "termEndDate", 
            label: "Term End", 
            options: { customBodyRender: (value) => value ? moment(value).format("DD-MM-YYYY") : "N/A" }
        },
        { name: "productName", label: "Product Name" },
        { name: "premiumFreq", label: "Policy Type" },
        { name: "insuredName", label: "Insured Name" },
        { name: "transPk", label: "TRANS PK" },
        { name: "transType", label: "Transaction Type" },
        { name: "Status", label: "Status" },
        { name: "paymentReference", label: "Payment Reference" },
        { name: "riskId", label: "RIKS PK" },
        { name: "riskName", label: "Risk Name" },
        { name: "motorDesc", label: "Motor Desc" },
        { name: "motorMake", label: "Motor Make" },
        { name: "motorPK", label: "Motor PK" },
        { name: "s_CoverageCode", label: "Coverage Codes" },
        { name: "agenciesName", label: "Brokers" },
        { name: "agentName", label: "Agent" },
        { 
            name: "sumInsuredValue", 
            label: "Total Sum Insured", 
            options: { customBodyRender: (value) => formatAmount.withCommas(formatNumber(value)) }
        },
        { 
            name: "totalFinalPre", 
            label: "Total Premium", 
            options: { customBodyRender: (value) => formatAmount.withCommas(formatNumber(value)) }
        },
        // { 
        //     name: "proRatePrem", 
        //     label: "Pro Rate Premium", 
        //     options: { customBodyRender: (value) => formatAmount.withCommas(formatNumber(value)) }
        // },
        { 
            name: "bookingDate", 
            label: "Booking Date", 
            options: { customBodyRender: (value) => value ? moment(value).format("DD-MM-YYYY") : "N/A" }
        },
        { 
            name: "netRetentionSI", 
            label: "NETRETENTION SI", 
            options: { customBodyRender: (value) => formatAmount.withCommas(formatNumber(value)) }
        },
        { 
            name: "netRetention", 
            label: "NETRETENTION", 
            options: { customBodyRender: (value) => formatAmount.withCommas(formatNumber(value)) }
        },
        { 
            name: "quotaSharingSI", 
            label: "QUOTASHARING SI", 
            options: { customBodyRender: (value) => formatAmount.withCommas(formatNumber(value)) }
        },
        { 
            name: "quotaSharing", 
            label: "QUOTASHARING", 
            options: { customBodyRender: (value) => formatAmount.withCommas(formatNumber(value)) }
        },
        { 
            name: "surplusSI", 
            label: "SURPLUS SI", 
            options: { customBodyRender: (value) => formatAmount.withCommas(formatNumber(value)) }
        },
        { 
            name: "surplus", 
            label: "SURPLUS", 
            options: { customBodyRender: (value) => formatAmount.withCommas(formatNumber(value)) }
        },
        { 
            name: "autoFacultativeSI", 
            label: "Auto Facultative SI", 
            options: { customBodyRender: (value) => formatAmount.withCommas(formatNumber(value)) }
        },
        { 
            name: "autoFacultative", 
            label: "Auto Facultative", 
            options: { customBodyRender: (value) => formatAmount.withCommas(formatNumber(value)) }
        },
        { 
            name: "facultativeSI", 
            label: "FACULTATIVE SI", 
            options: { customBodyRender: (value) => formatAmount.withCommas(formatNumber(value)) }
        },
        { 
            name: "facultative", 
            label: "FACULTATIVE", 
            options: { customBodyRender: (value) => formatAmount.withCommas(formatNumber(value)) }
        },
        { name: "treatyName", label: "Treaty Name" },
        { name: "regulatoryMappingName", label: "Regulatory Mapping Name" },
        { name: "facPlacementNo", label: "FAC PLACEMENT NO" },
        { name: "riskBand", label: "RISK BAND" },
        { name: "maxTransYesNo", label: "MAX TRANS YES / NO" }
    ];

    const fetchReport = async () => {
        setLoading(true);
        try {
            const response = await ReportAPI.fetchFilterNewWrittenPremiumReports(fdateRange);
            
            const formattedData = response.data.data.map(policy => {   
                const premium = policy.transType === 'ENDORSE' ? policy.pro_rate_premium : policy.totalFinalPre;

                return {
                    policyNumber: policy.policyNumber || "N/A",
                    termStartDate: policy.termStartDate || null,
                    termEndDate: policy.termEndDate || null,
                    productName: policy.productName || "N/A",
                    premiumFreq: policy.premiumFreq || "N/A",
                    insuredName: policy.insuredName || policy.cName || "N/A",
                    transPk: policy.transPk || "N/A",
                    transType: policy.transType || "N/A",
                    Status: policy.Status || "N/A",
                    paymentReference: policy.gfsPolicyNo || "N/A",
                    riskId: policy.riskId || "N/A",
                    riskName: policy.riskName || "N/A",
                    motorDesc: policy.motorDesc || "N/A",
                    motorMake: policy.motorMake || "N/A",
                    motorPK: policy.motorPK || "N/A",
                    s_CoverageCode: policy.s_CoverageCode || "N/A",
                    agenciesName: policy.agenciesName || "N/A",
                    agentName: policy.agentName || "N/A",
                    sumInsuredValue: formatNumber(policy.sumInsuredValue),
                    totalFinalPre: formatNumber(premium),
                    // proRatePrem: formatNumber(policy.raw_pro_rate_value),
                    bookingDate: policy.bookingDate || null,
                    netRetentionSI: formatNumber(policy.netRetentionSI),
                    netRetention: formatNumber(policy.netRetention),
                    quotaSharingSI: formatNumber(policy.quotaSharingSI),
                    quotaSharing: formatNumber(policy.quotaSharing),
                    surplusSI: formatNumber(policy.surplusSI),
                    surplus: formatNumber(policy.surplus),
                    autoFacultativeSI: formatNumber(policy.autoFacultativeSI),
                    autoFacultative: formatNumber(policy.autoFacultative),
                    facultativeSI: formatNumber(policy.facultativeSI),
                    facultative: formatNumber(policy.facultative),
                    treatyName: policy.treaty_name || "N/A",
                    regulatoryMappingName: policy.regulatoryMappingName || "N/A",
                    facPlacementNo: policy.facPlacementNo || "N/A",
                    riskBand: policy.riskBand || "N/A",
                    maxTransYesNo: policy.maxTransYesNo || "N/A"
                };
            });
            
            setPolicies(formattedData);
        } catch (error) {
            console.error("Error fetching report data:", error);
        }
        setLoading(false);
    };

    const exportToExcel = () => {
        const fileExtension = ".xlsx";
        const fileName = `WrittenPremiumReport_${moment().format("YYYYMMDD_HHmmss")}`;

        const dataToExport = policies.map(policy => ({
            "Policy No.": policy.policyNumber,
            "Term Start": policy.termStartDate ? moment(policy.termStartDate).format("DD-MM-YYYY") : "N/A",
            "Term End": policy.termEndDate ? moment(policy.termEndDate).format("DD-MM-YYYY") : "N/A",
            "Product Name": policy.productName,
            "Policy Type": policy.premiumFreq,
            "Insured Name": policy.insuredName,
            "TRANS PK": policy.transPk,
            "Transaction Type": policy.transType,
            "Status": policy.Status,
            "Reference": policy.paymentReference,
            "RIKS PK": policy.riskId,
            "Risk Name": policy.riskName,
            "Motor Desc": policy.motorDesc,
            "Motor Make": policy.motorMake,
            "Motor PK": policy.motorPK,
            "Coverage Codes": policy.s_CoverageCode,
            "Brokers": policy.agenciesName,
            "Agent": policy.agentName,
            "Total Sum Insured": policy.sumInsuredValue,
            "Total Premium": policy.totalFinalPre,
            // "Pro Rate Premium": policy.proRatePrem,
            "Booking Date": policy.bookingDate ? moment(policy.bookingDate).format("DD-MM-YYYY") : "N/A",
            "NETRETENTION SI": policy.netRetentionSI,
            "NETRETENTION": policy.netRetention,
            "QUOTASHARING SI": policy.quotaSharingSI,
            "QUOTASHARING": policy.quotaSharing,
            "SURPLUS SI": policy.surplusSI,
            "SURPLUS": policy.surplus,
            "Auto Facultative SI": policy.autoFacultativeSI,
            "Auto Facultative": policy.autoFacultative,
            "FACULTATIVE SI": policy.facultativeSI,
            "FACULTATIVE": policy.facultative,
            "Treaty Name": policy.treatyName,
            "Regulatory Mapping Name": policy.regulatoryMappingName,
            "FAC PLACEMENT NO": policy.facPlacementNo,
            "RISK BAND": policy.riskBand,
            "MAX TRANS YES / NO": policy.maxTransYesNo
        }));

        const ws = utils.json_to_sheet(dataToExport);

        // Define numeric columns
        const numericColumns = [
            "Total Sum Insured", "Total Premium", "Pro Rate Premium",
            "NETRETENTION SI", "NETRETENTION", "QUOTASHARING SI",
            "QUOTASHARING", "SURPLUS SI", "SURPLUS",
            "Auto Facultative SI", "Auto Facultative",
            "FACULTATIVE SI", "FACULTATIVE"
        ];

        // Get the range of the worksheet
        const range = utils.decode_range(ws['!ref']);
        
        // Find column indices for numeric columns
        const headerRow = Object.keys(dataToExport[0]);
        numericColumns.forEach(colName => {
            const colIndex = headerRow.indexOf(colName);
            if (colIndex !== -1) {
                // Apply number format to each cell in the column
                for (let rowIndex = range.s.r + 1; rowIndex <= range.e.r; rowIndex++) {
                    const cellRef = utils.encode_cell({ r: rowIndex, c: colIndex });
                    if (ws[cellRef]) {
                        ws[cellRef].t = 'n'; // Set cell type to number
                        ws[cellRef].z = '#,##0.00'; // Set number format to 2 decimal places
                    }
                }
            }
        });

        const wb = { 
            Sheets: { "Written Premium Report": ws }, 
            SheetNames: ["Written Premium Report"] 
        };

        writeFile(wb, `${fileName}${fileExtension}`);
    };

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wrapper_content reports_wrapper_content">
                    <div className="form_wrapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By Date Range</label>
                                <RangePicker
                                    format="YYYY-MM-DD"
                                    onChange={(value, dateString) => setFdateRange(dateString || [])}
                                />
                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={fetchReport} className="btn btn-primary">Search</Button>
                                    <Button onClick={exportToExcel} className="btn btn-success" style={{ marginLeft: "10px" }}>Export to Excel</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <MUIDataTable 
                        title={"Written Premium Report"} 
                        data={policies} 
                        columns={columns} 
                        options={options} 
                    />
                </div>
            </Spin>
        </Fragment>
    );
}