import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Spin, Col, Row, DatePicker, Button } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'
import moment from "moment";

const { RangePicker } = DatePicker;

function ClaimBordereaux() {

    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);

    const [fdateRange, setFdateRange] = useState([]);

    // useEffect(async () => {
    //     setLoading(true)
    //     await onClickFilter();
    // }, []);

    const options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        downloadOptions: { filename: "ClaimBordereauxReport.csv", separator: "," },
        elevation: 6,
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // build the json, this is one way to do it
            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    // if (columns[idx].name === 'Reserve Amt'
                    //     || columns[idx].name === 'Payment Amt') {
                    //     temp[columns[idx].name] = v == '' ? '' : parseFloat(v);
                    // } else {
                    //     temp[columns[idx].name] = v;
                    // }
                    temp[columns[idx].name] = v;
                })
                result.push(temp)
                return result
            }, [])

            const fileName = `ClaimBordereauxReport`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            // cancel default  CSV download from table
            return false
        }
    };

    const columns = [
        // "testClaimId",
        "Claim No",
        "Policy Number",
        "Product Name",
        "Insured Name",
        "Reported Date",
        "Date Of Loss",
        "Claim Type",
        "Risk Address",
        "Payment Date",
        "Trans Type",
        "Trans Sub Type",
        "Reserve Amt",
        "Group Name",
        "Coverage Name",
        "Payment Amt",
        "Motor Desc",
        "Motor Desc2",
        "Net_percent",
        "Quota_percent",
        "Surplus_percent",
        "Fac_percent",
        "Netretention_Amt",
        "Quotasharing_Amt",
        "Surplus_Amt",
        "Facultative_Amt",
        "Tran# PK",
        "Risk Master FK",
        "Motor PK",
        "Netretention_SI",
        "Quotasharing_SI",
        "Surplus_SI",
        "Facultative_SI",
        "Parent Coverage Code",
        "s_TreatyName",
        "Regulatory Mapping Name",
        "Term Start",
        "Term End",
        "VATInclude",
        "Description",
    ];

    const onClickFilter = () => {
        const data = { fdateRange: fdateRange }
        const formatDate = (dateString) => {
            if (!dateString || dateString === '') {
                return 'N/A';
            }
            
            const parsedDate = moment(dateString);
            return parsedDate.isValid() ? parsedDate.format('DD-MM-YYYY') : 'N/A';
        };
        
        setLoading(true)
        ReportAPI.getClaimBordereaux(data)
            .then(res => {
                let data = [];
 
                res.data.records.forEach(report => {

                    let productName = '';
                    if (report.policy_type) {
                        productName = report.policy_type.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }
  ///
                    let customerName = '';
                    if (report.customerName) {
                        customerName = report.customerName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();

                        });
                    }

                    if (report.payment_amt === null) {
                        report.payment_amt = 0
                    }
                    
                    // if (report.product_id === 1) {
                    //     coverage_name = "Life";
                    // } else if (report.product_id === 2) {
                    //     coverage_name = "Third Party Only";
                    // } else if (report.product_id === 3) {
                    //     coverage_name = "Motor Value"
                    // } else if (report.product_id === 5) {
                    //     coverage_name = "Mobile & Electronic Devices";
                    // }
                    let motordesc2= `${report.make?report.make:'-'}: ${report.model? report.model:'-'}`;
                    let revAmt = report.claim_reserves_transaction_type == 44 ? `-${Math.abs(report.payment_amt).toFixed(2)}` : report.reserve_amt;
                    let payAmt = report.claim_reserves_transaction_type == 44 ? '' : report.payment_amt;
                    // report.claim_reserves_transaction_type == 44 ? `-${Math.abs(report.payment_amt).toFixed(2)}` : report.reserve_amt
                    // report.claim_reserves_transaction_type == 44 ? '' : report.payment_amt

                    if (report.transSubType == 'Salvage' || report.transSubType == 'Subrogation') {
                        revAmt = revAmt === null ? '' : `-${revAmt}`;
                        payAmt = `-${payAmt}`;
                    }
                    const termStart = formatDate(report.term_start);
                    const termEnd = formatDate(report.term_end);
                    let resData = [
                        // "ID"+report.testClaimId,
                        report.claim_number,
                        report.policyNumber,
                        productName,
                        customerName,                      
                        formatDate(report.date_reported_to_alpha),
                        report.dateOfLoss, 
                        report.claim_type,
                        report.address !== null ? report.address : '-',
                        report.paymentDate !== '' ? moment(report.paymentDate ?? '').format('DD-MM-YYYY') : '',
                        report.transactionType !== null ? report.transactionType : '-',
                        report.transSubType !== null ? report.transSubType : '-',
                        revAmt != '-0' ? revAmt : 0,
                        report.coverage_codes,
                        report.coverage_name,
                        
                        // report.claim_reserves_transaction_type == 44 ? `-${Math.abs(report.payment_amt).toFixed(2)}` : report.reserve_amt,
                        // report.claim_reserves_transaction_type == 44 ? '' : report.payment_amt,  
                        payAmt != '-0' ? payAmt : 0,
                        report.vehiclePlate !== null ? report.vehiclePlate : '-',
                        motordesc2,
                        '-',
                        '-',
                        '-',
                        '-',
                        '-',
                        '-',
                        '-',
                        '-',
                        report.claim_reserves_coverages_id,
                        report.risk_address_id,
                        report.vehicle_id, 
                        '-',
                        '-',
                        '-',
                        '-',
                        report.parent_coverage_code !== '' ? report.parent_coverage_code : '',
                        report.treaty_names,
                        report.regulatory_mapping,
                       termStart,
                       termEnd,
                       report.include_vat,
                        report.claim_description !== null ? report.claim_description : '',
                        // reportTreatyName,
                        
                        // '-',
                        // '-',
                        // '-',
                        // '-',
                        // '-',
                        // '-',
                        // '-',
                        // '-',
                        // '-',
                        // '-',
                        // '-',

                    ];

                    data = [resData, ...data]
                })
                setReports(data);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label >Filter By date   </label>
                                <RangePicker allowClear
                                    format="YYYY-MM-DD"
                                    onChange={
                                        (value, dateString) => {
                                            value !== null ? setFdateRange(dateString) : setFdateRange([])
                                        }
                                    }
                                />
                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickFilter} className="btn btn-primary">Search</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <MUIDataTable
                        title={"Claim Bordereaux Report"}
                        data={reports}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}

export default ClaimBordereaux
