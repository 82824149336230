import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Divider, Grid, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { Alert, Badge, Card, Tabs } from 'antd';
import { TrendingUp, MapPin, AlertCircle } from 'lucide-react';
import { 
  CardHeader as MuiCardHeader, 
  CardContent as MuiCardContent 
} from '@material-ui/core';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { 
  BarChartOutlined, 
  UserOutlined, 
  AppstoreOutlined, 
  GlobalOutlined, 
  TeamOutlined
} from '@ant-design/icons';
import DashboardInsights from './comp/Insight';
import { ReportAPI } from '../../apis/ReportAPI';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ProductsTab from './comp/product';
import AgentTab from './comp/AgentTab';
import SeasonalityTab from './comp/SeasonalityInsights';
import ReactivatedTab from './comp/ReactivatedTab';

// Create a consistent Card Header and Content components
const CardHeader = ({ children, className }) => (
  <MuiCardHeader
    title={children}
    className={className}
  />
);

const CardContent = ({ children }) => (
  <MuiCardContent>
    {children}
  </MuiCardContent>
);

// Overview Tab Component
const OverviewTab = ({ salesData, cancellationData }) => {
  
  const {
    geographical_analysis,
    agent_analysis,
    product_analysis,
    feedback_analysis
  } = cancellationData;

  const topCities = geographical_analysis?.high_risk_cities.slice(0, 5);

  const topAgents = agent_analysis?.high_risk_agents.slice(0, 5);

  const topProducts = product_analysis?.high_risk_products.slice(0, 5);

  const topReasons = Object.entries(feedback_analysis?.reasons)
    .sort((a, b) => b[1] - a[1])
    .slice(0, 5)
    .map(([reason, count]) => ({ reason, count }));

    const consentRelatedReasons = Object.entries(feedback_analysis?.circumstances)
    .filter(([reason, count]) => reason.toLowerCase().includes('consent'));
  
  // Then sum up the counts from these entries
  const totalConsentCount = consentRelatedReasons.reduce((sum, [reason, count]) => sum + count, 0);
  

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF'];
  return (
  <Box p={3}>
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">Policy Sales vs Predictions</Typography>
            </Box>
          </CardHeader>
          <CardContent>
            <Box style={{ height: 400 }}>
              <ResponsiveContainer>
                <LineChart data={salesData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line 
                    type="monotone" 
                    dataKey="actualSales" 
                    stroke="#1890ff" 
                    name="Actual Sales" 
                    strokeWidth={2} 
                  />
                  <Line 
                    type="monotone" 
                    dataKey="predictedSales" 
                    stroke="#52c41a" 
                    name="Predicted Sales" 
                    strokeWidth={2} 
                    strokeDasharray="5 5" 
                  />
                </LineChart>
              </ResponsiveContainer>
            </Box>
          </CardContent>
        </Card>

      </Grid>

      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">Premium Revenue vs Predictions</Typography>
            </Box>
          </CardHeader>
          <CardContent>
            <Box style={{ height: 400 }}>
              <ResponsiveContainer>
                <LineChart data={salesData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line 
                    type="monotone" 
                    dataKey="actualPremium" 
                    stroke="#1890ff" 
                    name="Actual Premium" 
                    strokeWidth={2} 
                  />
                  <Line 
                    type="monotone" 
                    dataKey="predictedPremium" 
                    stroke="#52c41a" 
                    name="Predicted Premium" 
                    strokeWidth={2} 
                    strokeDasharray="5 5" 
                  />
                </LineChart>
              </ResponsiveContainer>
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={4}>
          <Card>
            <CardHeader title="Total Cancelled Policies" />
            <CardContent>
            <Typography variant="h6" align="center">Cancelled Policies</Typography>
              <Typography variant="h6" align="center">
                {cancellationData.overview.total_cancelled_policies}  
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Total Premium Lost */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader title="Total Premium Lost" />
            <CardContent>
            <Typography variant="h6" align="center">Premium Lost</Typography>
              <Typography variant="h6" align="center">
                {cancellationData.overview.total_premium_lost} 
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Average Policy Lifetime */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader title="Average Policy Lifetime" />
            <CardContent>
            <Typography variant="h6" align="center">Average Policy Lifetime</Typography>
              <Typography variant="h6" align="center">
                {cancellationData.overview.average_policy_lifetime.toFixed(2)} days
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Top 5 High-Risk Cities (Pie Chart) */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title="Top 5 High-Risk Cities" />
            <Typography variant="h6">Top 5 Cities Cancellation</Typography>
            <CardContent>
              <ResponsiveContainer width="100%" height={350}>
                <PieChart>
                  <Pie
                    data={topCities}
                    dataKey="cancelled_policies"
                    nameKey="city"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    fill="#8884d8"
                    label
                  >
                    {topCities.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        {/* Top 5 High-Risk Agents (Card with List) */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title="Top 5 High-Risk Agents" />
            <Typography variant="h6">Top 5 High-Risk Agents</Typography>
            <CardContent>
              <List>
                {topAgents.map((agent, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={agent.agent_name}
                      secondary={`Cancellations: ${agent.cancelled_policies}`}
                    />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>

        {/* Top 5 High-Risk Products (List) */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title="Top 5 High-Risk Products" />
            <Typography variant="h6">Top 5 High-Risk Products</Typography>
            <CardContent>
              <List>
                {topProducts.map((product, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={product.product_name}
                      secondary={`Cancellations: ${product.cancelled_policies}`}
                    />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>

        {/* Top 5 Cancellation Reasons (List) */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title="Top 5 Cancellation Reasons" />
            <Typography variant="h6">Top 5 Cancellation Reasons</Typography>
            <CardContent>
              <List>
                {topReasons.map((reason, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={reason.reason}
                      secondary={`Count: ${reason.count}`}
                    />
                  </ListItem>
                ))}
              </List>
            </CardContent>
            <Typography variant="h6" color='red'>Warning: {`There are more than ${totalConsentCount} cancellations related to consent reasons`}</Typography>
          </Card>
         
        </Grid>
    </Grid>
  </Box>
  )
};

const { TabPane } = Tabs;

// Agents Tab Component

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(min(100%, 450px), 1fr))',
    gap: '24px',
    padding: '24px'
  },
  card: {
    '&:hover': {
      boxShadow: '0 4px 12px rgba(0,0,0,0.08)'
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  icon: {
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    backgroundColor: '#f5f5f5'
  },
  impactBadge: {
    padding: '4px 12px',
    borderRadius: '16px',
    fontSize: '0.75rem',
    fontWeight: 500
  },
  impactHigh: {
    backgroundColor: '#FEE2E2',
    color: '#DC2626'
  },
  impactMedium: {
    backgroundColor: '#FEF3C7',
    color: '#D97706'
  },
  impactLow: {
    backgroundColor: '#D1FAE5',
    color: '#059669'
  },
  date: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem'
  },
  metricsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  metricItem: {
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1)
  },
  recommendationsList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    marginTop: theme.spacing(2)
  },
  recommendationItem: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(1),
    '&:before': {
      content: '""',
      width: '6px',
      height: '6px',
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      marginRight: theme.spacing(1),
      marginTop: '8px'
    }
  }
}));

const DemographicsTab = ({ insights }) => {
  const classes = useStyles();

  const getIcon = (type) => {
    switch (type) {
      case 'demographic':
        return <TrendingUp style={{ color: '#2563EB' }} />;
      case 'regional':
        return < MapPin style={{ color: '#059669' }} />;
      default:
        return null;
    }
  };

  const getImpactClass = (level) => {
    switch (level) {
      case 'high':
        return classes.impactHigh;
      case 'medium':
        return classes.impactMedium;
      default:
        return classes.impactLow;
    }
  };

  const formatMetrics = (metrics) => {
    if (typeof metrics === 'string') {
      try {
        metrics = JSON.parse(metrics.replace(/'/g, '"'));
      } catch (e) {
        return {};
      }
    }
    return metrics;
  };

  const data = insights.data.filter((insight) => 
    ['demographic', 'regional'].includes(insight.type)
  );

  return (
    <div className={classes.root}>
      {data.map((insight) => (
        <Card key={insight.id} className={classes.card}>
          <Box p={3}>
            <div className={classes.header}>
              <div className={classes.iconWrapper}>
                <div className={classes.icon}>
                  {getIcon(insight.type)}
                </div>
                <span className={`${classes.impactBadge} ${getImpactClass(insight.impact_level)}`}>
                  {insight.impact_level.charAt(0).toUpperCase() + insight.impact_level.slice(1)} Impact
                </span>
              </div>
              <Typography className={classes.date}>
                {new Date(insight.created_at).toLocaleDateString(undefined, {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric'
                })}
              </Typography>
            </div>

            <Typography variant="h6" gutterBottom>
              {insight.title}
            </Typography>
            <Typography variant="body2" color="textSecondary" paragraph>
              {insight.description}
            </Typography>

            <Box mt={3}>
              <Typography variant="subtitle2" gutterBottom>
                Key Metrics
              </Typography>
              <div className={classes.metricsGrid}>
                {Object.entries(formatMetrics(insight.metrics)).map(([key, value]) => (
                  <div key={key} className={classes.metricItem}>
                    <Typography variant="caption" color="textSecondary" style={{ textTransform: 'capitalize' }}>
                      {key.replace(/_/g, ' ')}
                    </Typography>
                    <Typography variant="subtitle1">
                      {typeof value === 'number' 
                        ? value % 1 === 0 
                          ? value.toLocaleString()
                          : value.toFixed(2)
                        : value}
                    </Typography>
                  </div>
                ))}
              </div>
            </Box>

            <Divider style={{ margin: '24px 0' }} />

            <Box>
              <Typography variant="subtitle2" gutterBottom>
                Recommendations
              </Typography>
              <ul className={classes.recommendationsList}>
                {insight.recommendations.split(',').map((rec, index) => (
                  <li key={index} className={classes.recommendationItem}>
                    <Typography variant="body2" color="textSecondary">
                      {rec.trim()}
                    </Typography>
                  </li>
                ))}
              </ul>
            </Box>
          </Box>
        </Card>
      ))}
    </div>
  );
};


const InsuranceDashboard = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const [allData, setAllData] = useState({
    analytics: null,
    dashboard: null,
    revenue: null
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const countryCode = useSelector(state => state.mainCountry);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        setLoading(true);
        
        const [analyticsResponse, dashboardResponse, revenueResponse] = await Promise.all([
          fetch('http://localhost:8001/predict', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
            },
            mode: 'cors',
          }),
          ReportAPI.fetchDashboardData(countryCode),
          ReportAPI.fetchDashboardV2Data(),
        ]);

        if (!analyticsResponse.ok) {
          throw new Error(`Analytics API request failed: ${analyticsResponse.statusText}`);
        }

        const analyticsResult = await analyticsResponse.json();
        
        setAllData({
          analytics: analyticsResult,
          dashboard: dashboardResponse?.data || null,
          revenue: revenueResponse?.data || null
        });
      } catch (err) {
        console.error('Error fetching dashboard data:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, [countryCode]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <CircularProgress/>
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive" className="m-6">
        Error loading dashboard data: {error}
      </Alert>
    );
  }

  const salesData = allData.analytics?.predictions?.chartData?.map(item => ({
    date: item.date,
    actualSales: item.actual_sales,
    predictedSales: item.predicted_sales,
  })) || [];

  const predicted_premium = allData.analytics?.premium_forecast?.validation?.predicted;
  const actual_premium = allData.analytics?.premium_forecast?.validation?.actual;

  const combined = actual_premium && predicted_premium && actual_premium.length === predicted_premium.length
  ? actual_premium.map((actualValue, index) => ({
      actualPremium: actualValue,
      predictedPremium: predicted_premium[index],
    }))
  : [];

const combinedData = salesData.map((salesItem, index) => ({
      ...salesItem,
      ...combined[index],
    }))

  const cancellationData = allData.analytics?.cancellation_analysis;
  const predictedSales = allData.analytics?.predictions?.chartData?.map(d => d.predicted_sales) || [];
  const agent_data= allData?.analytics?.insights?.agents;
  return (
    <Box p={3} bgcolor="#f0f2f5" minHeight="100vh">
      <DashboardInsights 
        predictedSales={predictedSales}
        dashboardData={allData.dashboard}
      />
      <Card>
        <Tabs defaultActiveKey="overview" size="large">
          <TabPane tab={<span><BarChartOutlined />Overview</span>} key="overview">
            <OverviewTab salesData={combinedData} cancellationData={cancellationData} />
          </TabPane>
          <TabPane tab={<span><UserOutlined />Demographics</span>} key="demographics">
            <DemographicsTab insights={allData.revenue} />
          </TabPane>
          <TabPane tab={<span><AppstoreOutlined />Products</span>} key="products">
            <ProductsTab products={allData?.analytics?.insights?.products ?? []} />
          </TabPane>
          <TabPane tab={<span><TeamOutlined />Agents</span>} key="agents">
            <AgentTab agentData={agent_data} />
          </TabPane>
          <TabPane tab={<span><BarChartOutlined/>Seasonality Analysis</span>} key="seasonality">
            <SeasonalityTab insights={allData?.revenue?.data} />
          </TabPane>
          <TabPane tab={<span><TeamOutlined/>Fraud Detection</span>} key='fraud'>
            <ReactivatedTab/>
          </TabPane>
        </Tabs>
      </Card>
    </Box>
  );
};

export default InsuranceDashboard;