import React, { useState, useEffect } from 'react';
import { 
  Card, 
  CardContent, 
  Typography, 
  Grid, 
  CircularProgress,
  Paper
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { 
  TrendingUp, 
  TrendingDown,
  CompareArrows,
  Assessment,
  Warning
} from '@material-ui/icons';
import { ReportAPI } from '../../../apis/ReportAPI';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  insightCard: {
    height: '100%',
    marginBottom: theme.spacing(2),
  },
  positive: {
    color: '#52c41a',
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  negative: {
    color: '#f5222d',
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  neutral: {
    color: '#1890ff',
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  insightValue: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginTop: '8px',
    marginBottom: '4px'
  },
  insightDescription: {
    color: 'rgba(0, 0, 0, 0.45)'
  }
}));



const InsightCard = ({ title, value, trend, description, icon, type = 'neutral' }) => {
  const classes = useStyles();
  const getColorClass = () => {
    switch(type) {
      case 'positive': return classes.positive;
      case 'negative': return classes.negative;
      default: return classes.neutral;
    }
  };


  return (
    <Card className={classes.insightCard}>
    <CardContent>
      <div className={getColorClass()}>
        {icon}
        <Typography variant="h6">{title}</Typography>
      </div>
      <Typography className={classes.insightValue}>
        {value}
        {trend && <span style={{ fontSize: '16px', marginLeft: '8px' }}>({trend})</span>}
      </Typography>
      <Typography className={classes.insightDescription}>
        {description}
      </Typography>
    </CardContent>
  </Card>
  );
};

// DashboardInsights now receives dashboardData directly instead of fetching it
const DashboardInsights = ({ predictedSales, dashboardData }) => {
  if (!dashboardData) return null;

  const { todayData, yesterdayData, todayTopAgents, yesterdayTopAgents } = dashboardData;

  // Calculate metrics
  const todayTotal = todayData.reduce((sum, item) => sum + item.totalPolicy, 0);
  const yesterdayTotal = yesterdayData.reduce((sum, item) => sum + item.totalPolicy, 0);
  const salesChange = todayTotal - yesterdayTotal;
  const salesChangePercent = ((salesChange / yesterdayTotal) * 100).toFixed(1);

  const averagePredicted = predictedSales.reduce((a, b) => a + b, 0) / predictedSales.length;
  const predictedVsActual = ((averagePredicted - todayTotal) / todayTotal * 100).toFixed(1);

  const topAgent = todayTopAgents.length > 0 
    ? todayTopAgents[todayTopAgents.length - 1] 
    : null;
 
  const agentsWithDecline = todayTopAgents.filter(agent => {
    const yesterdayAgent = yesterdayTopAgents.find(y => y.agent_id === agent.agent_id);
    return yesterdayAgent && agent.policy_count < yesterdayAgent.policy_count;
  });

  return (
    <>
    <Grid container spacing={3} style={{ marginBottom: '24px' }}>
       <Grid item xs={12} md={3}>
        <InsightCard
          title="Today's Sales vs Prediction"
          value={todayTotal}
          trend={`${predictedVsActual}%`}
          description={`${predictedVsActual > 0 ? 'Above' : 'Below'} predicted trend`}
          icon={<Assessment />}
          type={predictedVsActual >= 0 ? 'positive' : 'negative'}
        />
      </Grid>
      <Grid item xs={12} md={3}>
      <InsightCard
        title="Sales Change"
        value={`${salesChange >= 0 ? '+' : ''}${salesChange}`}
        trend={`${salesChangePercent}%`}
        description="Compared to yesterday"
        icon={salesChange >= 0 ? <TrendingUp /> : <TrendingDown />}
        type={salesChange >= 0 ? 'positive' : 'negative'}
      />
      </Grid>
      <Grid item xs={12} md={3}>
      <InsightCard
        title="Top Agent Performance"
        value={topAgent ? `${topAgent.policy_count}` : 'N/A'}
        description={topAgent ? `policies sold today` : 'No sales recorded'}
        icon={<CompareArrows />}
        type="neutral"
      />
    </Grid>
    <Grid item xs={12} md={3}>
      <InsightCard
        title="Agents Needing Attention"
        value={agentsWithDecline.length}
        description="Agents showing decreased sales"
        icon={<Warning />}
        type={agentsWithDecline.length > 0 ? 'negative' : 'positive'}
      />
       </Grid>
    </Grid>
    </>
  );
};

export default DashboardInsights;