import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Grid, 
  Divider,
  makeStyles 
} from '@material-ui/core';
import { Card, Tabs } from 'antd';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { 
  CardHeader as MuiCardHeader, 
  CardContent as MuiCardContent 
} from '@material-ui/core';

// Create a consistent Card Header and Content components
const CardHeader = ({ children, className }) => (
  <MuiCardHeader
    title={children}
    className={className}
  />
);

const CardContent = ({ children }) => (
  <MuiCardContent>
    {children}
  </MuiCardContent>
);

const { TabPane } = Tabs;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    backgroundColor: '#f0f2f5',
    minHeight: '100vh'
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold'
  },
  subtitle: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(3)
  },
  tabContainer: {
    marginBottom: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  tab: {
    padding: theme.spacing(2, 4),
    fontWeight: 500,
    cursor: 'pointer',
    '&.active': {
      color: theme.palette.primary.main,
      borderBottom: `2px solid ${theme.palette.primary.main}`
    }
  },
  overviewCard: {
    marginBottom: theme.spacing(3),
    height: '100%'
  },
  metricValue: {
    textAlign: 'center',
    padding: theme.spacing(2),
    '& .value': {
      fontSize: '1.875rem',
      fontWeight: 'bold'
    },
    '& .label': {
      marginTop: theme.spacing(0.5),
      fontSize: '0.875rem',
      color: theme.palette.text.secondary
    }
  },
  chartContainer: {
    height: 400
  },
  detailsCard: {
    marginBottom: theme.spacing(3)
  },
  detailsGrid: {
    padding: theme.spacing(2)
  },
  detailItem: {
    marginBottom: theme.spacing(2)
  },
  metricContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  progressBar: {
    width: '100%',
    height: 8,
    backgroundColor: '#f0f0f0',
    borderRadius: 5,
    overflow: 'hidden'
  },
  progressFill: {
    height: '100%',
    borderRadius: 5
  },
  starContainer: {
    display: 'flex'
  },
  productTags: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1)
  },
  productTag: {
    display: 'inline-block',
    backgroundColor: '#e6f7ff',
    color: '#1890ff',
    borderRadius: 16,
    padding: theme.spacing(0.5, 1.5),
    fontSize: '0.75rem',
    fontWeight: 600,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const AgentTab = ({ agentData }) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState('topAgents');
  
  // Extract data from the agentData prop
  const topAgentData = agentData[0]?.metrics?.top_agents || [];
  const agentsNeedingSupportData = [
    ...(agentData[5]?.metrics?.average_agents || []),
    ...(agentData[1]?.metrics?.struggling_agents || [])
  ];
  const agencyData = agentData[2]?.metrics?.top_agencies || [];
  const productDiversityData = agentData[4]?.metrics?.top_diverse_agents || [];

  // Determine which data to display based on active tab
  const getDisplayData = () => {
    switch(activeTab) {
      case 'topAgents':
        return topAgentData;
      case 'needsSupport':
        return agentsNeedingSupportData;
      case 'agencies':
        return agencyData;
      case 'products':
        return productDiversityData;
      default:
        return topAgentData;
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <Box className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        Insurance Agent Performance Dashboard
      </Typography>
      <Typography variant="body1" className={classes.subtitle}>
        Comprehensive analysis of agent performance, agency metrics, and product distribution
      </Typography>
      
      {/* Tab Navigation */}
      <Tabs activeKey={activeTab} onChange={handleTabChange} size="large">
        <TabPane tab="Top Performing Agents" key="topAgents" />
        <TabPane tab="Agents Needing Support" key="needsSupport" />
        <TabPane tab="Agency Performance" key="agencies" />
        <TabPane tab="Product Diversity" key="products" />
      </Tabs>

      <Grid container spacing={3}>
        {/* Top Metrics Overview */}
        <Grid item xs={12}>
         
        </Grid>

        {/* Conditional Content Based on Tab */}
        {activeTab === 'topAgents' && (
          <>
            <Grid item xs={12} md={6}>
              <Card className={classes.overviewCard}>
                <CardHeader>Top Agent Performance</CardHeader>
                <CardContent>
                  <Box className={classes.chartContainer}>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={topAgentData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis yAxisId="left" domain={[0, 100]} />
                        <YAxis yAxisId="right" orientation="right" />
                        <Tooltip />
                        <Legend />
                        <Bar yAxisId="right" dataKey="activation_rate" fill="#52c41a" name="Activation Rate (%)" />
                      </BarChart>
                    </ResponsiveContainer>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card className={classes.overviewCard}>
                <CardHeader>Premium Generation by Top Agents</CardHeader>
                <CardContent>
                  <Box className={classes.chartContainer}>
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart data={topAgentData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line 
                          type="monotone" 
                          dataKey="premium" 
                          stroke="#1890ff" 
                          name="Premium Generated (USD)"
                          strokeWidth={2}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </>
        )}

        {activeTab === 'needsSupport' && (
          <>
            <Grid item xs={12} md={6}>
              <Card className={classes.overviewCard}>
                <CardHeader>Agents Needing Support - Performance Metrics</CardHeader>
                <CardContent>
                  <Box className={classes.chartContainer}>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={agentsNeedingSupportData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis domain={[0, 100]} />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="performance" fill="#ff4d4f" name="Performance Score" />
                        <Bar dataKey="activation_rate" fill="#ff7a45" name="Activation Rate (%)" />
                      </BarChart>
                    </ResponsiveContainer>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </>
        )}

        {activeTab === 'agencies' && (
          <>
            <Grid item xs={12} md={6}>
              <Card className={classes.overviewCard}>
                <CardHeader>Agency Premium Generation</CardHeader>
                <CardContent>
                  <Box className={classes.chartContainer}>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={agencyData} layout="vertical">
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis type="number" />
                        <YAxis dataKey="name" type="category" width={150} />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="premium" fill="#1890ff" name="Total Premium (USD)" />
                      </BarChart>
                    </ResponsiveContainer>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card className={classes.overviewCard}>
                <CardHeader>Agency Metrics Comparison</CardHeader>
                <CardContent>
                  <Box className={classes.chartContainer}>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={agencyData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis yAxisId="left" domain={[0, 120]} />
                        <YAxis yAxisId="right" orientation="right" domain={[0, 5]} />
                        <Tooltip />
                        <Legend />
                        <Bar yAxisId="left" dataKey="agent_count" fill="#1890ff" name="Number of Agents" />
                        <Bar yAxisId="right" dataKey="avg_products" fill="#722ed1" name="Avg Products per Agent" />
                        <Bar yAxisId="left" dataKey="activation_rate" fill="#52c41a" name="Activation Rate (%)" />
                      </BarChart>
                    </ResponsiveContainer>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </>
        )}

        {activeTab === 'products' && (
          <>
            {/* <Grid item xs={12} md={6}>
              <Card className={classes.overviewCard}>
                <CardHeader>Top Diverse Agents</CardHeader>
                <CardContent>
                  <Box className={classes.chartContainer}>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={productDiversityData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis yAxisId="left" domain={[0, 6]} />
                        <YAxis yAxisId="right" orientation="right" />
                        <Tooltip />
                        <Legend />
                        <Bar yAxisId="left" dataKey="product_count" fill="#722ed1" name="Number of Products" />
                        <Bar yAxisId="right" dataKey="premium" fill="#13c2c2" name="Premium Generated (USD)" />
                      </BarChart>
                    </ResponsiveContainer>
                  </Box>
                </CardContent>
              </Card>
            </Grid> */}
          </>
        )}

        {/* Agent Details Cards */}
        <Grid item xs={12}>
          <Card className={classes.detailsCard}>
            <CardHeader>{`${activeTab === 'agencies' ? 'Agency' : 'Agent'} Details`}</CardHeader>
            <CardContent>
              <Grid container spacing={3} className={classes.detailsGrid}>
                {getDisplayData().map((item, index) => (
                  <Grid item xs={12} md={4} key={index}>
                    <Card>
                      <CardHeader>{item.name}</CardHeader>
                      <CardContent>
                        {activeTab !== 'agencies' && activeTab!=='products' &&(
                          <>
                            <Box className={classes.detailItem}>
                              <Box className={classes.metricContainer}>
                                <Typography color="textSecondary">Performance Score</Typography>
                                <Typography 
                                  variant="h6" 
                                  style={{ 
                                    color: item.performance < 30 ? '#ff4d4f' : 
                                           item.performance > 70 ? '#52c41a' : 
                                           'inherit'
                                  }}
                                >
                                  {item.performance || 0}%
                                </Typography>
                              </Box>
                              <Box className={classes.progressBar}>
                                <Box 
                                  className={classes.progressFill} 
                                  style={{ 
                                    width: `${item.performance || 0}%`,
                                    backgroundColor: item.performance < 30 ? '#ff4d4f' : 
                                                    item.performance > 70 ? '#52c41a' : 
                                                    '#faad14'
                                  }}
                                />
                              </Box>
                            </Box>

                            <Box className={classes.detailItem}>
                              <Box className={classes.metricContainer}>
                                <Typography color="textSecondary">Activation Rate</Typography>
                                <Typography 
                                  variant="h6" 
                                  style={{ 
                                    color: item.activation_rate < 20 ? '#ff4d4f' : 
                                           item.activation_rate > 70 ? '#52c41a' : 
                                           'inherit'
                                  }}
                                >
                                  {item.activation_rate || 0}%
                                </Typography>
                              </Box>
                              <Box className={classes.progressBar}>
                                <Box 
                                  className={classes.progressFill} 
                                  style={{ 
                                    width: `${item.activation_rate || 0}%`,
                                    backgroundColor: item.activation_rate < 20 ? '#ff4d4f' : 
                                                    item.activation_rate > 70 ? '#52c41a' : 
                                                    '#faad14'
                                  }}
                                />
                              </Box>
                            </Box>
                          </>
                        )}

                        {activeTab === 'agencies' && (
                          <>
                            <Box className={classes.detailItem}>
                              <Box className={classes.metricContainer}>
                                <Typography color="textSecondary">Activation Rate</Typography>
                                <Typography 
                                  variant="h6" 
                                  style={{ 
                                    color: item.activation_rate < 20 ? '#ff4d4f' : 
                                           item.activation_rate > 70 ? '#52c41a' : 
                                           'inherit'
                                  }}
                                >
                                  {Math.round(item.activation_rate || 0)}%
                                </Typography>
                              </Box>
                              <Box className={classes.progressBar}>
                                <Box 
                                  className={classes.progressFill} 
                                  style={{ 
                                    width: `${item.activation_rate || 0}%`,
                                    backgroundColor: item.activation_rate < 20 ? '#ff4d4f' : 
                                                    item.activation_rate > 70 ? '#52c41a' : 
                                                    '#faad14'
                                  }}
                                />
                              </Box>
                            </Box>

                            <Box className={classes.metricContainer} mb={2}>
                              <Typography color="textSecondary">Number of Agents</Typography>
                              <Typography variant="h6">{item.agent_count}</Typography>
                            </Box>

                            <Box className={classes.metricContainer} mb={2}>
                              <Typography color="textSecondary">Avg Products per Agent</Typography>
                              <Typography variant="h6">{Math.round(item.avg_products)}</Typography>
                            </Box>
                          </>
                        )}

                        <Box className={classes.metricContainer} mb={2}>
                          <Typography color="textSecondary">Premium Generated</Typography>
                          <Typography variant="h6">${item.premium.toLocaleString()}</Typography>
                        </Box>

                        {activeTab !== 'agencies' && activeTab !== 'products' && (
                          <Box className={classes.metricContainer} mb={1}>
                            <Typography color="textSecondary">Products</Typography>
                            <Box className={classes.starContainer}>
                              {item.products && item.products.split(',').length > 0 ? (
                                [...Array(item.products.split(',').length)].map((_, i) => (
                                  <span key={i} role="img" aria-label="star" style={{ color: '#faad14' }}>⭐</span>
                                ))
                              ) : null}
                            </Box>
                          </Box>
                        )}

                        {activeTab === 'products' && (
                          <Box mb={1}>
                            <Typography color="textSecondary" style={{ marginBottom: 8 }}>Products Offered:</Typography>
                            <div className={classes.productTags}>
                              {item.products ? 
                                item.products.split(',').map((product, i) => (
                                  <span key={i} className={classes.productTag}>
                                    {product.trim()}
                                  </span>
                                ))
                              : 
                                (item.productsList ? 
                                  item.productsList.split(',').map((product, i) => (
                                    <span key={i} className={classes.productTag}>
                                      {product.trim()}
                                    </span>
                                  ))
                                : null)
                              }
                            </div>
                          </Box>
                        )}

                        {activeTab !== 'agencies' && activeTab !== 'products' && (
                          <Box mb={1}>
                            <Typography color="textSecondary" style={{ marginBottom: 4 }}>Agency</Typography>
                            <Typography>{item.agency}</Typography>
                          </Box>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AgentTab;