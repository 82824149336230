import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Grid,
  Card,
  CardContent,
  Chip,
  CircularProgress
} from '@material-ui/core';
import { ReportAPI } from '../../../apis/ReportAPI';

const ReactivatedTab = () => {
    const [policies, setPolicies] = useState([]);
    const [loading, setLoading] = useState(true);
  
    // Load data on component mount
    useEffect(() => {
      setLoading(true);
      // Use the actual API call
      ReportAPI.fetchFraudData()
        .then((data) => {
          
          setPolicies(data.data.policies || []);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching reactivated policies:", error);
          setLoading(false);
        });
    }, []);
//   console.log(policies);
    // Calculate summary statistics directly from all policies
    const totalReactivatedPolicies = policies.length;
    const avgDaysBetween = policies.length > 0 
      ? Math.round(policies.reduce((sum, policy) => sum + policy.days_between_policies, 0) / policies.length) 
      : 0;
    
    const premiumDifference = policies.reduce((sum, policy) => {
      return sum + (policy.new_premium - policy.original_premium);
    }, 0).toFixed(2);
  
    // Risk level distribution
    const riskDistribution = policies.reduce((acc, policy) => {
      acc[policy.risk_level] = (acc[policy.risk_level] || 0) + 1;
      return acc;
    }, {});
  
  // Helper function for risk level chip color
  const getRiskColor = (riskLevel) => {
    if (riskLevel.includes('Low')) return 'success';
    if (riskLevel.includes('Medium')) return 'warning';
    if (riskLevel.includes('High')) return 'error';
    return 'default';
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Reactivated Policies Monitor
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Monitor and analyze policy reactivations of the last 60 days, updated weekly
      </Typography>

      {/* Summary Cards */}
      <Grid container spacing={3} sx={{ mb: 3, mt: 1 }}>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="subtitle2" color="primary">
                Total Reactivations
              </Typography>
              <Typography variant="h4">{totalReactivatedPolicies}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="subtitle2" color="primary">
                Avg. Days Between
              </Typography>
              <Typography variant="h4">{avgDaysBetween} days</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="subtitle2" color="primary">
                Premium Difference
              </Typography>
              <Typography variant="h4">${premiumDifference}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="subtitle2" color="primary">
                Risk Distribution
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                {Object.entries(riskDistribution).map(([risk, count], idx) => (
                  <Typography key={idx} variant="body2">
                    {risk.replace(' Risk', '')}: {count}
                  </Typography>
                ))}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Table */}
      <TableContainer component={Paper}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 4 }}>
            <CircularProgress size={40} />
            <Typography variant="body1" style={{ marginLeft: 16 }}>
              Loading policies...
            </Typography>
          </Box>
        ) : (
          <Table aria-label="reactivated policies table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>Old Policy #</TableCell>
                <TableCell>New Policy #</TableCell>
                <TableCell>Customer Name</TableCell>
                <TableCell>Agent</TableCell>
                <TableCell>Product</TableCell>
                <TableCell>Old Premium</TableCell>
                <TableCell>New Premium</TableCell>
                <TableCell>Cancel Date</TableCell>
                <TableCell>Reactivation</TableCell>
                <TableCell>Days</TableCell>
                <TableCell>Reason</TableCell>
                <TableCell>Risk Level</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {policies.map((policy) => (
                <TableRow key={policy.id} hover>
                  <TableCell>{policy.original_policy}</TableCell>
                  <TableCell>{policy.new_policy}</TableCell>
                  <TableCell>{policy.customer_name}</TableCell>
                  <TableCell>{policy.agent_name}</TableCell>
                  <TableCell>{policy.product_name}</TableCell>
                  <TableCell>${policy.original_premium}</TableCell>
                  <TableCell>${policy.new_premium}</TableCell>
                  <TableCell>{policy.cancelled_date}</TableCell>
                  <TableCell>{policy.activated_date}</TableCell>
                  <TableCell>{policy.days_between_policies}</TableCell>
                  <TableCell>{policy.cancellation_reason || '-'}</TableCell>
                  <TableCell>
                    <Chip 
                      label={policy.risk_level} 
                      color={getRiskColor(policy.risk_level)}
                      size="small"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </Box>
  );
};

export default ReactivatedTab;