import { Container, Typography, Grid, Box } from "@material-ui/core";
import ReportRequestForm from "./comp/ReportRequestForm";
import RequestQueue from "./comp/RequestQueue";

const Index = () => {
  return (
    <Box sx={{ minHeight: "100vh", bgcolor: "#f5f5f5", py: 6, px: 2 }}>
      <Container maxWidth="xl">
        <Box sx={{ textAlign: "center", mb: 6 }}>
          <Typography variant="h3" component="h1" gutterBottom>
            Report Request System
          </Typography>
          <Typography align="center"  variant="h6" color="text.secondary">
            Submit and track your report requests in one place
          </Typography>
        </Box>

        {/* Changed Grid container direction to column */}
        <Grid container direction="column" spacing={4}>
          <Grid item xs={12}>
            <ReportRequestForm />
          </Grid>
          
          <Grid item xs={12}>
            <RequestQueue />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Index;