import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Col, Row, DatePicker, Button, Spin } from "antd";
import { ReportAPI } from '../../../../apis/ReportAPI';
import moment from "moment";
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'
import { useSelector } from "react-redux";

const { RangePicker } = DatePicker;
export default function RiskReport() {
    const countryCode = useSelector(state => state.mainCountry)
    const [loading, setLoading] = useState(false);
    const [policies, setPolicies] = useState([]);
    const [fdateRange, setFdateRange] = useState([]);
    // useEffect(async () => {
    //     await onClickFilter();
    // }, []);

    const options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100, 200],
        downloadOptions: { filename: "SARiskReport.csv", separator: "," },
        elevation: 6,
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // build the json, this is one way to do it
            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    if (columns[idx].name === 'SA Premum') {
                        temp[columns[idx].name] = parseFloat(v);
                    } else {
                        temp[columns[idx].name] = v;
                    }
                })
                result.push(temp)
                return result
            }, [])

            const fileName = `SARiskReport`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            // cancel default  CSV download from table
            return false
        }
    };

    const columns = [
        "Policy No",
        "Policy Inception Year",
        "Policy Inception Month", 
        "Policy Status",
        "Policyholder Name",
        "Broker",
        "Branch",
        "Salesperson",
        "Risk ID",
        "Risk Description",
        "Risk Inception Year",
        "Risk Inception Month",
        "Option",
        "Premium",
        "Commission",
        "Premium Less Commission"
     ];

    const onClickFilter = () => {
        setLoading(true);
        const data = { fdateRange };
    
        ReportAPI.getSARiskReport(data)
            .then(res => {
                let data = [];
                res.data.policies.forEach(policy => {
                    const year = moment(policy.created_at).format('YYYY');
                    const month = moment(policy.created_at).format('MM');
                    let resData = [
                        policy.policyNumber || '',
                        year,
                        month,
                        policy.policyStatus || '',
                        policy.customerName || '',
                        policy.agencyName || '-',
                        '',
                        policy.agentName || '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        policy.premium ? parseFloat(policy.premium).toFixed(2) : '-',
                        '',
                        '',
                        ];
                    data = [resData, ...data];
                });
                setPolicies(data);
            })
            .catch(error => console.error(error))
            .finally(() => setLoading(false));
    };
    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                       <Row>
                                                   <Col xs={24} lg={5} className="select_content">
                                                       <label >Filter By date range </label>
                                                       <RangePicker allowClear
                                                           format="YYYY-MM-DD"
                                                           onChange={
                                                               (value, dateString) => {
                                                                   setFdateRange(dateString)
                                                               }
                                                           }
                                                       />
                                                   </Col>
                       
                                                   <Col xs={24} lg={5} className="select_content">
                                                       <label>&nbsp;</label>
                                                       <div className="button_search_content">
                                                           <Button onClick={onClickFilter} className="btn btn-primary">Search</Button>
                                                       </div>
                                                   </Col>
                                               </Row>
                    </div>
                    <MUIDataTable
                        title={"SA Premium Report"}
                        data={policies}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}
